<template>
  <SlidingPanel
    ref="sliding-panel"
    @slidingPanelClickOutside="confirmHide"    
  >
    <HdCircleLoader
      v-if="uc.isLoading"
    />
    <!-- navigation -->
    <template #header>
      <v-container fluid>
        <v-row class="mb-1">
          <span
            id="title"
            class="text-h5"
          > {{ formTitle }}</span>   
          <v-spacer></v-spacer>
          <HdIconButton 
            v-if="companyId === 1 && $accesscontrol().isGlobalAdmin"
            :icon="isImpersonateAccount ? 'fas fa-ghost' : 'far fa-ghost'"
            round
            :top="false"
            :tooltip="isImpersonateAccount ? $t('RemoveMyImpersonateAccount') : $t('AddAsMyImpersonateAccount')"
            @click="onToggleImpersonateAccount"
          />
        </v-row>
        <v-row>
          <v-btn
            color="secondarygreen3"        
            depressed
            small
            @click="onNavigationButtonClick('title')"
          >
            info
          </v-btn>
          <v-btn
            color="secondarygreen3"        
            class="mx-1"
            depressed
            small
            @click="onNavigationButtonClick('properties')"
          >
            {{ $t('Properties') }}
          </v-btn>
          <v-btn
            color="secondarygreen3"        
            class="mx-1"
            depressed
            small
            @click="onNavigationButtonClick('licences')"
          >
            {{ $t('Licenses') }}
          </v-btn>
          <v-btn
            v-if="!isNew"
            color="secondarygreen3"        
            class="mx-1"
            depressed
            small
            @click="onNavigationButtonClick('subdivisions')"
          >
            {{ $t('Subdivisions') }}
          </v-btn>
        </v-row>
      </v-container>
    </template>
    <!-- navigation end -->

    <!-- form -->
    <template>
      <HdCreateEditUserForm 
        v-if="!uc.isLoading" 
        ref="form"
        :user="user"
        :password-policy-id="passwordPolicyId"
        :allow-empty-password="!isNew"
        :show-db-id="!isNew"
        :show-avatar="!isNew"
      />
      <v-container
        v-if="!uc.isLoading" 
        fluid
      >
        <!-- user group -->
        <HdGroupSelector 
          ref="group"
          :selected-group="user.groupId"
        /> 
        <!-- properties -->
        <PropertyList
          id="properties"
          ref="propform"
          :selected-properties="user.properties"
        />
          
        <!-- licenses -->
        <ApplicationToggleList 
          id="licences"
          ref="appform"
          :selected-applications="user.applications"
        />
        <UserSubdivisionList
          v-show="!isNew"
          id="subdivisions"
          ref="subdivisionlist"
          :user="user"
        />
        <UserShortcutList
          v-show="!isNew && $accesscontrol().isPartnerAdmin && hasDrift"
          id="shortcuts"
          ref="shortcutlist"
          :user="user"
          :company-id="companyId"
        />
        <v-select
          v-model="user.defaultMail"
          :items="mailTypes"
          item-text="text"
          item-value="value"
          :label="$t('DefaultEmail')"
          :hint="$t('DefaultEmail')"
          persistent-hint
          single-line
        ></v-select>
        <MailSignatureEditor
          v-if="user && user.userDbId"
          ref="signatureEditor"
          type="UserDbId"
          :external-id="user.userDbId"
        />
      </v-container>
    </template>
    <template #footer>
      <v-btn
        v-show="!uc.isLoading"
        color="success"
        :loading="user.isLoading"
        @click="onSubmit"
      >
        {{ $t('Save') }}
      </v-btn>
      <v-btn            
        v-show="!uc.isLoading"
        text
        @click="confirmHide"
      >
        {{ $t('Close') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn            
        v-if="$accesscontrol().isSuperAdmin && !isNew"
        text
        color="error"
        @click="moveDialogOpen = true"
      >
        {{ $t('Move') }}
      </v-btn>
      <v-btn
        v-show="!uc.isLoading"
        v-if="user.has2fa"
        color="error"
        text
        :loading="removing2fa"
        @click.prevent="onRemove2Fa"
      >
        {{ $t('Reset2FA') }}
      </v-btn>
    </template>
    <HdCustomizableConfirmModal 
      ref="customconfirm"
    />
    <ChangeUserCompanyDialog 
      v-model="moveDialogOpen"
      :user="user"
      @usermoved="hide()"
    />
  </SlidingPanel>
</template>

<script>
import { User } from '../../../model/user/User'
import { UserCollection } from '../../../model/user/UserCollection'
import SlidingPanel from '../../SlidingPanel'
import { mapGetters } from 'vuex'
import HdCreateEditUserForm from './HdCreateEditUserForm'
import PropertyList from '../PropertyList'
import ApplicationToggleList from '../ApplicationToggleList'
import HdGroupSelector from '../../form/field/HdGroupSelector'
import UserSubdivisionList from '../../list/UserSubdivisionList'
import ChangeUserCompanyDialog from './ChangeUserCompanyDialog.vue'
import UserShortcutList from '../../list/UserShortcutList.vue'
import { Company } from '../../../model/company/Company'
import MailSignatureEditor from '../subdivision/MailSignatureEditor.vue'

export default {
  name: 'CreateEditUserPanel',
  components: {
    SlidingPanel,
    HdCreateEditUserForm,
    ApplicationToggleList,
    HdGroupSelector,
    UserSubdivisionList,
    PropertyList,
    UserShortcutList,
    ChangeUserCompanyDialog,
    MailSignatureEditor,
  },
  props: {
    standAlone: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    company: {
      type: Company,
      required: false,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      formTitle: '',
      dialogOpen: false,
      user: new User({}),
      companyId: 0,
      isNew: false,
      uc: new UserCollection({}),
      removing2fa: false,
      passwordPolicyId: -1,
      mailTypes: [
        { text: '', value: 'None' },
        { text: this.$t('CompanyEmail'), value: 'CompanyMail' },
        { text: this.$t('UserEmail'), value: 'UserMail' },
      ],
      moveDialogOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      getImpersonateAccount: 'user/getImpersonateAccount'
    }),
    isImpersonateAccount() {
      return this.getImpersonateAccount?.userDbId === this.user.userDbId
    },
    hasDrift() {
      return this.company && this.company.properties.findIndex(prop => prop.propertyId === 11 && prop.propertyValue) > -1
    },
  },

  methods: {
    /**
     * Display user edit / create from
     *
     * @param {number} userDbId selected user 
     * @param {number} companyId company where user is added / edited
     * @param {number} passwordPolicyId company's password policy 
     */
    show(userDbId, companyId, passwordPolicyId) {
      if(userDbId && userDbId > 0) {   
        this.isNew = false
      
        this.uc.fetchById(userDbId)
        .then((res) => {
          this.user = res
          return this.user.is2FaEnabled()   
        })
        .catch((ex) => {
          this.$alert({ message: this.$handleError(ex), type: 'error'})
          this.$refs['sliding-panel'].close()
          return false
        })
      } else {
        this.isNew = true
        this.user = new User({active: true})
        this.$refs.form.validate()
      }
      this.companyId = companyId   
      this.passwordPolicyId = passwordPolicyId           
      this.formTitle = this.isNew ? this.$t('AddNewUser') : this.$t('EditUser')
      this.$refs['sliding-panel'].open()
    },

    confirmHide() {
      //this.user.features = null
      this.user.groupId = this.$refs.group.getValue()
      //this.user.endpointId = null
      this.user.password = this.$refs.form.getValues().password || ''
      this.user.properties = this.$refs.propform.getValues()
      this.user.applications = this.$refs.appform.getValues()
      const hasChanges = this.user.hasChanges() || (this.$refs.signatureEditor && this.$refs.signatureEditor.hasChanges())
      const notValid = !this.$refs.form.validate()
      
      if (hasChanges) {
        this.$refs.customconfirm.show({ 
          title: this.$t('Confirm'), 
          text: this.$t('DiscardUnsavedChanges'),
          buttons: [
            { id: 'discard', text: this.$t('Discard'), textOnly: true },
            { id: 'save', text: this.$t('Save'), textOnly: true, disabled: notValid },
            { id: 'stay', text: this.$t('KeepEditing'), color: 'primary' },
          ]
        })
        .then((id) => {
          if (id === 'discard') {
            this.hide()
          } else if (id === 'save') {
            this.onSubmit()
          }
        })
      } else {
        this.hide()
      }
    },
    /**
     * Hide this dialog
     * 
     * Used outside of this component
     */
    hide() {
      this.$refs.form.clear()
      this.user = new User({active: true})
      this.$refs['sliding-panel'].close()
    },
    /**
     * Gets user data from form and emits an event where
     * user object is passed forward       
     */
    onSubmit() {        
        // TODO features
      if(!this.$refs.form.validate()) {
        return
      }
      
      let formValues = this.$refs.form.getValues()
      
      this.user.features = null
      this.user.groupId = this.$refs.group.getValue()
      this.user.endpointId = null
      this.user.password = formValues.password
      this.user.properties = this.$refs.propform.getValues()
      this.user.applications = this.$refs.appform.getValues()
      // this.user.properties = this.$refs.propform.getValues().map((p) => {
      //   return { propertyId: p.propertyId, propertyName: p.propertyName, propertyValue: p.propertyValue }
      // })       
      this.user.companyId = this.companyId
      const promise = this.$refs.signatureEditor ? this.$refs.signatureEditor.updateSignature() : Promise.resolve()

      promise
      .then(() => {
        this.$emit('userformsubmit', this.user, this.isNew)
      })
      .then(() => {
        if(this.standAlone) {
          return this.user.update()
          .then(() => {
            this.hide()
          })
        }
      })
    },

    onNavigationButtonClick(target) {
      let anchor = '#' + target
      this.$vuetify.goTo(anchor, {
        container: '.sliding-panel.expanded .sliding-panel-content'
      })
    },
    onRemove2Fa() {
      this.$confirm({
        color: 'error', 
        title: this.$t('Reset2FA'), 
        text: this.$t('Reset2FA'),
        confirmButtonText: this.$t('Remove'),        
      })
      .then(() => {
        this.removing2fa = true
        return this.user.remove2fa()
      })
      .then(()=>{
          this.$alert({ message: this.$t('Deleted'), type: 'success'})

      })
      .catch((ex) => {          
        if(ex !== 'cancel') {
          // api error  
          this.$alert({type: 'warning', message: this.$handleError(ex) })
        }
      })
      .finally(() => {
        this.removing2fa = false
      })

    },

    onToggleImpersonateAccount() {
      if(this.getImpersonateAccount?.userDbId === this.user.userDbId) {
        this.$store.dispatch('user/setImpersonateAccount', null)
      }else {
        const storeObject = {
          userName: this.user.userName,
          userDbId: this.user.userDbId,
          userId: this.user.userId,
          companyId: this.companyId,
          passwordPolicyId: this.passwordPolicyId
        }
        this.$store.dispatch('user/setImpersonateAccount', storeObject)
      }
    },

    /**
     * Display subdivision selection
     */
    showSubdivisionSelect() {
      //
      this.isNew = false
      return this.$refs.subdivisionlist.onConnectionDialog()
    },

  },
}
</script>
<style scoped>

</style>