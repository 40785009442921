import { EventBus } from '../util/EventBus'

export const ConfirmPlugin = {
  install: function (Vue) {
    /**
     * Show a confirm modal with the options
     */
    Vue.prototype.$confirm = function (opts) {
      //const _this = this //Vue instance
      const promise = new Promise((resolve, reject) => {
        const handler = function (result) {
          EventBus.$off('handleconfirm', handler)
          if (typeof result === 'boolean' && result) {
            resolve(result)
          } else {
            reject(result)
          }
        }
        EventBus.$emit('showconfirm', opts)
        EventBus.$on('handleconfirm', handler)
      })

      return promise
    }
  },
}
