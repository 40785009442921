import { OptionsProperty } from './OptionsProperty'
/**
 * Class MultipleOptionsProperty
 *
 * @extends OptionsProperty
 */
export class MultipleOptionsProperty extends OptionsProperty {
  constructor(props) {
    super(props)
    //Remove inherited empty value
    //Splicing the first one would be more efficient, but could break if the empty value is removed in the base class
    this.selection = this.selection.filter((item) => item.selectionKey && item.selectionValue)
    if (props.propertyValue && props.propertyValue.includes('|')) {
      this.propertyValue = props.propertyValue.replace('|', ',')
    }
  }

  set value(value) {
    this.propertyValue = value.join(',')
  }

  get value() {
    if (this.propertyValue && this.propertyValue.length) {
      return this.propertyValue.includes('|') ? this.propertyValue.split('|') : this.propertyValue.split(',')
    } else {
      return null
    }
  }
}
