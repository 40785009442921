import { currentEnvironment } from './tools'

export const ENTRE_SERVICE_PROXY_REST = 'https://router.entrecloud.se/proxy/IEntreComServiceRest/'
export const ENTRE_NET_API_URL = 'https://notinuse/'

const title = 'Portal Admin'

const urlMap = {
  development: {
    i18n: 'https://translations.entrecloud.se/api/Translations/Json/Main',
    userImage: 'https://image.staging.entrecloud.se/api/UserImage/',
    image: 'https://image.staging.entrecloud.se/api/',
    authApi: 'https://auth.staging.entrecloud.se/api/',
    adminApi: 'https://admin.staging.entrecloud.se/api/',
    commonAuth: 'https://services.staging.entrecloud.se/login?title=' + encodeURIComponent(title) + '&returnUrl=',
    email: 'https://mail.staging.entrecloud.se/api/',
    rss: 'https://rss.staging.entrecloud.se/api/',
    internal: 'https://internal.staging.entrecloud.se/api',
    dms: 'https://dms.staging.entrecloud.se/dmsapi',
    config: 'https://config.staging.entrecloud.se/api/',
    router: 'https://router.staging.entrecloud.se/',
  },
  staging: {
    i18n: 'https://translations.entrecloud.se/api/Translations/Json/Main',
    userImage: 'https://image.staging.entrecloud.se/api/UserImage/',
    image: 'https://image.staging.entrecloud.se/api/',
    authApi: 'https://auth.staging.entrecloud.se/api/',
    adminApi: 'https://admin.staging.entrecloud.se/api/',
    commonAuth: 'https://services.staging.entrecloud.se/login?title=' + encodeURIComponent(title) + '&returnUrl=',
    email: 'https://mail.staging.entrecloud.se/api/',
    rss: 'https://rss.staging.entrecloud.se/api/',
    internal: 'https://internal.staging.entrecloud.se/api',
    dms: 'https://dms.staging.entrecloud.se/dmsapi',
    config: 'https://config.staging.entrecloud.se/api/',
    router: 'https://router.staging.entrecloud.se/',
  },
  production: {
    i18n: 'https://translations.entrecloud.se/api/Translations/Json/Main',
    userImage: 'https://image.entrecloud.se/api/UserImage/',
    image: 'https://image.entrecloud.se/api/',
    authApi: 'https://auth.entrecloud.se/api/',
    adminApi: 'https://admin.entrecloud.se/api/',
    commonAuth: 'https://services.entrecloud.se/login?title=' + encodeURIComponent(title) + '&returnUrl=',
    email: 'https://mail.entrecloud.se/api/',
    rss: 'https://rss.entrecloud.se/api/',
    internal: 'https://internal.entrecloud.se/api',
    dms: 'https://dms.entrecloud.se/dmsapi',
    config: 'https://config.entrecloud.se/api/',
    router: 'https://router.entrecloud.se/',
  },
}

export const I18N_URL = urlMap[currentEnvironment()].i18n // process.env.VUE_APP_I18N_URL
export const USER_IMAGE_URL = urlMap[currentEnvironment()].userImage //process.env.VUE_APP_USER_IMAGE_URL
export const AUTH_SERVICE_URL = urlMap[currentEnvironment()].authApi //process.env.VUE_APP_AUTH_SERVICE_URL
export const ADMIN_API_URL = urlMap[currentEnvironment()].adminApi //process.env.VUE_APP_ADMIN_API_URL
export const IMAGE_URL = urlMap[currentEnvironment()].image //process.env.VUE_APP_USER_IMAGE_URL
export const COMMON_AUTH_PAGE_URL = urlMap[currentEnvironment()].commonAuth
export const EMAIL_API_URL = urlMap[currentEnvironment()].email
export const RSS_URL = urlMap[currentEnvironment()].rss
export const INTERNAL_URL = urlMap[currentEnvironment()].internal
export const DMS_API_URL = urlMap[currentEnvironment()].dms
export const CONFIG_URL = urlMap[currentEnvironment()].config
export const ROUTER_URL = urlMap[currentEnvironment()].router
