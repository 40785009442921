export class AlertMessage {
  /**
   *
   * @param {string} type - error, info
   * @param {string} message - the message
   * @param {string} component - component
   * @param {string} url - url
   * @param {string} apiMethod - backend method called
   */
  constructor({ type, message, component = '', url = '', apiMethod = '' }) {
    this.type = type
    this.message = message
    this.component = component
    this.url = url
    this.apiMethod = apiMethod
    this.datetime = null
    this.seen = false
  }
}
