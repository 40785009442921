import { isEqual } from 'lodash'

/**
 * Base data model
 *
 * Abstraction class to be used as base for all the data entities from API
 *
 */
export class BaseModel {
  constructor(params) {
    /**
     * @property {object} _initialValues Initial values from API
     */
    this._initialValues = params

    /**
     * @property {boolean} isLoading true, if model is doing async operation
     */
    this.isLoading = false

    /**
     * @property {boolean} dirty true, if model has changes
     */
    this.dirty = false
  }

  /**
   * Save data to API
   * override in sub classes
   *
   * TODO: Maybe switch this into collection class,
   * that way we wouldn't need to call collection.updateItem()
   *
   * @returns {boolean}
   */
  update() {
    throw new Error('save method not implemented.')
  }

  resetInitialValues() {
    Object.keys(this).forEach((field) => {
      if (field !== '_initialValues') {
        this[field] = this._initialValues[field]
      }
    })
  }

  /**
   * Used to modify the model before comparing changes. Run for both the current and initial model
   * Use to remove unneeded properties that might affect the comparison
   * Override in subclasses
   * @param {Object} model The model overriding this function (this)
   * @returns {Object} The same model
   */
  transformForComparison(model) {
    return model
  }

  /**
   * Has the models properties been edited after creation
   * @returns {Boolean}
   */
  hasChanges() {
    let current = JSON.parse(JSON.stringify(this))
    let initial = JSON.parse(JSON.stringify(new this.constructor(this._initialValues)))
    current._initialValues = null
    initial._initialValues = null
    current = this.transformForComparison(current)
    initial = this.transformForComparison(initial)
    //console.log(current)
    //console.log(initial)
    return !isEqual(current, initial)
  }
}
