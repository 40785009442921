import { BaseCollection } from '../BaseCollection'
import { Company } from './Company'
import adminApiClient from '../../api/admin-api-client'

/**
 * Class CompanyCollection
 *
 * @extends BaseCollection
 */

export class CompanyCollection extends BaseCollection {
  constructor(props) {
    super(props)

    this.collectionKey = 'companyId'
  }

  /**
   * @override
   *
   * Get list of companies with paging
   * https://github.com/alirezanet/Gridify#supported-filtering-operators
   * @param {number} page
   * @param {number} pageSize
   * @param {string} orderBy
   * @param {string} filter
   * @returns {Company[]}
   */
  async fetch() {
    this.isLoading = true
    try {
      const companiesResponse = await adminApiClient.getCompanies({
        Page: this.page,
        PageSize: this.pageSize,
        OrderBy: this.orderBy,
        Filter: this.filter,
      })
      this.count = companiesResponse.data.count
      this.items = companiesResponse.data.data.map((c) => new Company(c))
      this.isLoading = false

      return this.items
    } catch (ex) {
      this.isLoading = false
      throw new Error(ex.message)
    }
  }

  /**
   * @override
   */
  async fetchById(companyId) {
    this.isLoading = true
    try {
      this.isLoading = true
      const companyResponse = await adminApiClient.getCompany(companyId)

      this.items = [new Company(companyResponse.data)]
      this.isLoading = false
      return this.items
    } catch (ex) {
      this.isLoading = false
      throw ex
    }
  }
  /**
   * @override
   *
   * @param {Company} company
   * @returns {Promise<number>} created company id
   */
  async create(company) {
    company.allowPersonalMail = company.allowPersonalMail ? 1 : 0
    company.subdivisions = null // TODO: why doesn't an empty array work?
    company.isLoading = true
    this.isLoading = true
    try {
      let response = await adminApiClient.createCompany({ data: company })

      if (response.status === 201) {
        company.companyId = response.data

        this.items.push(company)
        this.isLoading = false
        company.isLoading = false
        return company.companyId
      } else {
        throw new Error('Failed to create company')
      }
    } catch (ex) {
      this.isLoading = false
      company.isLoading = false
      throw ex
    }
  }

  async delete(companyId) {
    this.isLoading = true
    try {
      await adminApiClient.deleteCompany(companyId)
      this.isLoading = false
      return true
    } catch (ex) {
      this.isLoading = false
      throw ex
    }
  }
}
