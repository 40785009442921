<template>
  <v-dialog
    v-model="visible"
    persistent
    max-width="40%"
    style="z-index: 9999"
  >
    <v-card>
      <v-card-title>
        <v-icon          
          size="16"
          class="mx-2"
        >
          {{ icon }}
        </v-icon>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ text }}
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          v-for="btn in buttons" 
          :key="btn.id"
          elevation="0"
          :text="btn.textOnly"
          :color="btn.color"
          :disabled="btn.disabled"
          @click="handleClick(btn.id)"
        >
          {{ btn.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'HdCustomizableConfirmModal',
  data() {
    return {
      title: this.$t('Confirm'),
      text: '',
      icon: 'fas fa-exclamation-circle',
      visible: false,
      buttons: [], //Array of { id, text, color, textOnly, disabled }
      resolveFnc: undefined,
      rejectFnc: undefined,
    }
  },
  methods: {
    handleClick(id) {      
      this.visible = false
      this.resolveFnc(id)
    },

    /**
     * @param {object} opts
     * 
     * @returns {Promise}
     */
    show(opts = {}) {
      this.title = opts.title || this.title
      this.text = opts.text || this.text
      this.icon = opts.icon || this.icon
      this.buttons = opts.buttons || this.buttons
      this.visible = true
      
      return new Promise((resolve, reject) => {
        this.resolveFnc = resolve
        this.rejectFnc = reject
      })
    }
  }
}
</script>

<style scoped>

</style>
