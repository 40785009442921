<template>
  <div>
    <div>
      <div
        class="row my-5 px-5"
      >
        <div class="text-h5 inline-block">
          {{ $t('Subdivisions') }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          color="secondarygreen3"
          class="mx-1"
          depressed
          small
          :disabled="!user.userDbId"
          @click="onConnectionDialog"
        >
          <v-icon
            small
            class="mr-1"
          >
            far fa-link
          </v-icon>
          {{ $t('ConnectSubdivisionToUser') }}
        </v-btn>
      </div>
      <v-divider
        style="margin-bottom: 20px;"
      ></v-divider>

      <v-list
        dense
      >
        <v-list-item
          v-for="sd in sdc.items"
          :key="sd.id"
        >
          <v-list-item-content>
            <v-list-item-title>{{ sd.id }} {{ sd.text }}  </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('Endpoint') + ': ' + sd.endpointName }} 
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ $t('AdminExtEntreUser') + ': ' + getExternalUsername(sd.id) }} 
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-btn
            icon
            :loading="sd.isLoading"
            @click="deleteUserSubdivisionConnection(sd)"
          >
            <v-icon 
              small
              color="red lighten-1"
            >
              fas fa-trash
            </v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </div>
    <ConnectSubdivisionToUserDialog 
      ref="subdivisionconnectiondialog"
      :user="user"
      @connectionselected="onConnectionSetup"
    />
  </div>
</template>
<script>
import { User } from '../../model/user/User'
import { SubdivisionCollection } from '../../model/subdivision/SubdivisionCollection'
import ConnectSubdivisionToUserDialog from '../form/user/ConnectSubdivisionToUserDialog'
import { UserCollection } from '../../model/user/UserCollection'

export default {
  name: 'UserSubdivisionList',
  components: {
    ConnectSubdivisionToUserDialog
  },
  props: {
    user: {
      type: User,
      required: true,
    },
  },
  data() {
    return {
      sdc: new SubdivisionCollection({}),
      userNames: [],
      resolveFnc: undefined,
      rejectFnc: undefined,
    }
  },
  watch: {
    'user.userDbId': function(newVal) {
      if(newVal > 0 && this.sdc.loadCount === 0) {
        this.sdc.companyId = this.user.companyId
        this.sdc.userId = newVal        
        this.sdc.fetchByUserAndCompany()
      }
    },
    'sdc.items': function(newVal) {
      this.userNames = []
      newVal.forEach(val => {
        const uc = new UserCollection({})
        uc.filter = 'userId=' + this.user.userId
        uc.fetch({ companyId: this.user.companyId, subdivisionId: val.subdivisionId })
        .then((users) => {
          if (users.length === 1) {
            this.userNames.push({ subdivisionId: val.subdivisionId, externalCompanyUser: users[0].externalCompanyUser })
          }
        })
        .catch(()=>{})
      })
    }
  },
  methods: {    
    onConnectionDialog() {
      
      this.$refs.subdivisionconnectiondialog.open = true
      console.log('tests')
      return new Promise((resolve, reject) => {
        this.resolveFnc = resolve
        this.rejectFnc = reject
      })
    },
    deleteUserSubdivisionConnection(subdivision) {
      this.$confirm({
        color: 'warning', 
        title: this.$t('Delete'), 
        text: this.$t('ConfirmDeleteUserFromSubdivision', [this.user.userName, subdivision.text]),
        confirmButtonText: this.$t('Delete')
      })
      .then(() => {
        return this.sdc.removeUserSubdivisionConnection(this.user.userDbId, this.user.companyId, subdivision.id)
      })
      .then(() => {
        this.$toast({ type:'success', message: this.$t('UserDeletedFromSubdivision', [this.user.userName, subdivision.text]) })
      })
      .catch((ex) => {
        if(ex !== 'cancel') {
          this.$alert({ type:'error', message: this.$handleError(ex) })
        }
      })
    },
    onConnectionSetup(connectionObject) {
      this.sdc.connectUserToSubdivision(connectionObject)
      .then(() => {
        this.$refs.subdivisionconnectiondialog.open = false
        this.$refs.subdivisionconnectiondialog.showAdvancedSettings = false
        this.$toast({ type:'success', message: 'Conncetion added'})
        this.resolveFnc(true)
      })
      .catch((ex) => {
        this.$alert({ type:'error', message: this.$handleError(ex) })
        this.rejectFnc(false)
      })
    },
    getExternalUsername: function(subdivisionId) {
      const found = this.userNames.find(item => item.subdivisionId === subdivisionId)
      return found ? found.externalCompanyUser : ''
    }
  },
}
</script>