<template>
  <transition name="loader">
    <div
      class="text-center"
      style="padding: 10% 0px; position: absolute; top: 10%; left: 45%;"
    >
      <v-progress-circular  
        color="accent"
        size="90"
        width="6"
        indeterminate
      ></v-progress-circular>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'HdCircleLoader'
}
</script>

<style scoped>

.loaderenter, .loader-leave-to {
  opacity: 0;
}
.loader-enter-to, .loader-leave {
  opacity: 1;
}
.loader-enter-active {
  transition: all .2s ease;
}
.loader-leave-active {
  transition: all .4s ease;
}
</style>