import { Property } from './Property'
import { clientTranslator } from '../../i18n'
/**
 * Class OptionsProperty
 *
 * @extends Property
 */
export class OptionsProperty extends Property {
  constructor(props) {
    super(props)
    this.selection = [{ selectionKey: '', selectionValue: '' }].concat(props.selection)
    this.translateSelections()
  }
  translateSelections() {
    this.selection.forEach((s) => {
      s.selectionValue = clientTranslator(s.selectionValue)
    })
  }
}
