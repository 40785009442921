<template>
  <DxHtmlEditor
    value-type="html"
    :value="markup"
    :disabled="disabled"
    :placeholder="placeholderText"
    class="rich-text-editor"
    :allow-soft-line-break="true"
    @valueChanged="onChange"
    @initialized="onInitialize"
  >
    <DxMediaResizing :enabled="true" />
    <DxImageUpload :tabs="imgTabs" file-upload-mode="base64" />
    <DxToolbar :multiline="true">
      <DxItem name="undo" />
      <DxItem name="redo" />
      <DxItem name="separator" />
      <DxItem name="size" :accepted-values="sizeValues" />
      <DxItem name="font" :accepted-values="fontValues" />
      <DxItem name="lineheight" :accepted-values="lineHeights" widget="dxSelectBox" :options="lineHeightOptions" />
      <DxItem name="separator" />
      <DxItem name="bold" />
      <DxItem name="italic" />
      <DxItem name="strike" />
      <DxItem name="underline" />
      <DxItem name="separator" />
      <DxItem name="alignLeft" />
      <DxItem name="alignCenter" />
      <DxItem name="alignRight" />
      <DxItem name="alignJustify" />
      <DxItem name="separator" />
      <DxItem name="orderedList" />
      <DxItem name="bulletList" />
      <DxItem name="separator" />
      <DxItem name="header" :accepted-values="headerValues" />
      <DxItem name="separator" />
      <DxItem name="color" />
      <DxItem name="background" />
      <DxItem name="separator" />
      <DxItem name="link" />
      <DxItem name="image" />
      <DxItem name="separator" />
    </DxToolbar>
  </DxHtmlEditor>
</template>

<script>
import { DxHtmlEditor, DxToolbar, DxItem, DxMediaResizing, DxImageUpload } from 'devextreme-vue/html-editor'
import Quill from 'devextreme-quill'

export default {
  name: 'HdRichTextEditor',
  components: {
    DxHtmlEditor,
    DxToolbar,
    DxItem,
    DxMediaResizing,
    DxImageUpload,
  },
  model: {
    prop: 'markup',
    event: 'change',
  },
  props: {
    //The content html for the editor
    markup: {
      required: true,
      type: String,
    },
    placeholderText: {
      type: String,
      default: "Write text here",
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['change'],
  data() {
    return {
      imgTabs: ['file', 'url'],
      sizeValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
      fontValues: ['Arial', 'Georgia', 'Tahoma', 'Times New Roman', 'Verdana'],
      headerValues: [false, 1, 2, 3, 4, 5],
      lineHeights: ['1', '1.2', '1.5', '1.6', '1.8', '2', '2.4', '2.8', '3', '4', '5'],
      lineHeightOptions: { placeholder: 'Line Height' },
    }
  },
  methods: {
    onInitialize() {
      const Parchment = Quill.import('parchment')
      const config = {
        scope: Parchment.Scope.BLOCK,
        whitelist: null,
      }
      const LineHeightStyle = new Parchment.StyleAttributor('lineheight', 'line-height', config)
      Quill.register({ 'formats/lineHeight': LineHeightStyle })
    },
    onChange(changeObject) {
      this.$emit('change', changeObject.value)
    },
  },
}
</script>
<style scoped></style>
