<template>
  <v-text-field
    v-model.number="model.value"
    :label="model.text"
    type="number"
    :min="0"
    :max="maxInt"
    :rules="[v => !v || (v >= 0 && v <= maxInt) || ' ']"
    hide-spin-buttons
  >
  </v-text-field>
</template>
<script>
import { ComponentModel } from '../../../model/ComponentModel'
import { MAX_INT } from '../../../util/constants'

export default {
  name: 'HdNumberField',
  props: {
		model: {
			type: ComponentModel,
			required: true,			
		}
	},
  computed: {
    maxInt() {
      return MAX_INT
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
