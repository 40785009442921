<template>
  <v-tooltip 
    :top="top"
    :bottom="!top"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed            
        v-bind="attrs"
        :icon="round"
        min-width="20"
        class="mx-1"
        v-on="on"
        @click="$emit('click')"
      >
        <v-icon
          small          
        >
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <span> {{ tooltip }} </span>
  </v-tooltip>
</template>
<script>

export default {
  name: 'HdIconButton',
  props: {
    tooltip: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    icon: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    round: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    top: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    },
    
  },
}
</script>