import { AlertMessage } from '../model/AlertMessage'
import { EventBus } from '../util/EventBus'

export const ToastPlugin = {
  install: function (Vue) {
    /**
     * Show  toast message
     */
    Vue.prototype.$toast = function (opts) {
      EventBus.$emit('showtoast', opts)
    }
    /**
     * Show  alert message
     */
    Vue.prototype.$alert = function (opts) {
      const alert = new AlertMessage(opts)
      EventBus.$emit('showalert', alert)
    }
  },
}
