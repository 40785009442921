import axios from 'axios'
import { CONFIG_URL } from '../util/URL.js'

const client = axios.create({
  baseURL: CONFIG_URL,
  timeout: 20000,
  headers: {
    'content-type': 'application/json',
  },
})

const configApiClient = {
  client: client,

  setToken(token) {
    this.client.defaults.headers['Authorization'] = 'Bearer ' + token
  },

  //Configs

  getConfigs(payload) {
    return this.client.post('Configs/query', payload)
  },
  getConfigsQuery(params) {
    return this.client.get('Configs', { params: params })
  },

  getConfig(configId) {
    return this.client.get('Configs/' + configId)
  },

  createConfig(payload) {
    return this.client.post('Configs/create', payload)
  },

  updateConfig(configId, payload) {
    return this.client.post('Configs/update/' + configId, payload)
  },

  deleteConfig(configId) {
    return this.client.delete('Configs/' + configId)
  },

  //Groups

  getGroups(payload) {
    return this.client.post('ConfigTypeGroups/query', payload)
  },

  getGroup(groupId) {
    return this.client.get('ConfigTypeGroups/' + groupId)
  },

  createGroup(payload) {
    return this.client.post('ConfigTypeGroups/create', payload)
  },

  updateGroup(groupId, payload) {
    return this.client.post('ConfigTypeGroups/update/' + groupId, payload)
  },

  deleteGroup(groupId) {
    return this.client.delete('ConfigTypeGroups/' + groupId)
  },

  //Types

  getTypes(payload) {
    return this.client.post('ConfigTypes/query', payload)
  },

  getType(typeId) {
    return this.client.get('ConfigTypes/' + typeId)
  },

  createType(payload) {
    return this.client.post('ConfigTypes/create', payload)
  },

  updateType(typeId, payload) {
    return this.client.post('ConfigTypes/update/' + typeId, payload)
  },

  deleteType(typeId) {
    return this.client.delete('ConfigTypes/' + typeId)
  },

  //Criteria

  getCriteria(payload) {
    return this.client.post('Criteria/query', payload)
  },

  getCriterion(criterionId) {
    return this.client.get('Criteria/' + criterionId)
  },

  createCriterion(payload) {
    return this.client.post('Criteria/create', payload)
  },

  updateCriterion(criterionId, payload) {
    return this.client.post('Criteria/update/' + criterionId, payload)
  },

  deleteCriterion(criterionId) {
    return this.client.delete('Criteria/' + criterionId)
  },

  getCriterionValueTypes(payload) {
    return this.client.post('CriterionValueTypes/query', payload)
  },
}

export default configApiClient
