import { Property } from './Property'
import { OptionsProperty } from './OptionsProperty'
import { MultipleOptionsProperty } from './MultipleOptionsProperty'
import { ComponentCollection } from '../ComponentCollection'
import { BooleanProperty } from './BooleanProperty'
import { ImpersonateProperty } from './ImpersonateProperty'

/**
 * Class PropertyCollection
 *
 * @extends ComponentCollection
 */
export class PropertyCollection extends ComponentCollection {
  /**
   * Pass data array to constructor method
   * if data is fetched before instantiation
   *
   * @param {Array} data
   */
  constructor(data) {
    super()
    this.setItems(data)
  }

  setItems(data) {
    this.items = data.map((p) => this.makeProperty(p)) // test with all props
  }

  makeProperty(config) {
    const propType = config.propertyValueType.toLowerCase()
    const lookupTable = {
      plug: MultipleOptionsProperty,
      lng: OptionsProperty,
      b: BooleanProperty,
      uni: OptionsProperty,
      imprs: ImpersonateProperty,
    }
    let cls = lookupTable[propType]
    return cls ? new lookupTable[propType](config) : new Property(config)
  }
}
