<template>
  <v-dialog
    v-model="visible"
    max-width="500px"
  > 
    <v-card class="card">
      <slot></slot>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'HdDialog',
  props: {
    value: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
  
}
</script>
<style scoped>
.card {
  background: var(--v-secondarymenubackground-base);
  padding: 8px;
  overflow: hidden;
}
</style>