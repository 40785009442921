import hdcolors from '../../style/colors.js'

export default {
  light: {
    primary: hdcolors.secondarygreen4,
    secondary: hdcolors.secondarygrey4,
    accent: hdcolors.primarygreen,

    onprimary: '#ffffff',
    onsecondary: '#000000',
    //default text color for light theme
    defaulttext: hdcolors.black2,

    appbackground: '#ffffff',

    secondarymenubackground: hdcolors.white,
    navigationbackground: hdcolors.darkbackground,

    // grid
    gridfilterbackground: '#fafafa',
    gridborder: '#e0e0e0',
    gritooledit: hdcolors.secondarygrey4,
    //
    ...hdcolors,
  },

  dark: {
    primary: hdcolors.secondarygreen4,
    secondary: hdcolors.darkbackground,
    accent: hdcolors.primarygreen,

    background: hdcolors.black2,

    onprimary: '#000000',
    onsecondary: '#ffffff',
    //default text color for dark theme
    defaulttext: hdcolors.white,
    secondarymenubackground: '#363636',
    appbackground: hdcolors.black2,
    navigationbackground: hdcolors.darkbackground,

    // grid
    gridfilterbackground: '#31313a',
    gridborder: '#515159',
    gritooledit: hdcolors.primarygreen,
    //

    ...hdcolors,
  },
}
