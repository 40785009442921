<template>
  <v-app
    id="hvd-app"   
  >
    <TheLoader :hidden="!isLoading" />
    <TheAppBar v-if="isAuthenticated" />
    <!-- <TheNavigation v-if="isAuthenticated" />     -->
    <TheAlertModal />
    <TheToast />
    <TheVersionInfo />
    <TheScrollTop />
    <HdConfirmModal shared />
    <v-main>  
      <transition
        name="slide-x-transition"
        mode="out-in"
      >
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import TheAppBar from './components/TheAppBar'
// import TheNavigation from './components/TheNavigation'
import TheLoader from './components/TheLoader'
import TheAlertModal from './components/TheAlertModal'
import TheToast from './components/TheToast'
import { mapState, mapGetters } from 'vuex'
// import { loadLanguageAsync } from './i18n'
import TheVersionInfo from './components/TheVersionInfo'
import TheScrollTop from './components/TheScrollTop' 
import themes from "devextreme/ui/themes"
import userflow from 'userflow.js'

export default {
  name: 'App',

  components: {
    TheAppBar,
    // TheNavigation,
    TheLoader,
    TheAlertModal,
    TheToast,
    TheVersionInfo,
    TheScrollTop
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      theme: 'ui/isDarkTheme',
    }),
    ...mapState({
      userLocale: (state) => state.user.locale,
      isLoading: 'isLoading',
    }),
    ...mapState('user', {
      user: (state) => state.tokenInfo,
    }),
  },
  watch: {
    theme(newVal) {
      //Set styles defined in public/index.html for DevExtreme components
      if (newVal) {
        themes.current("material.teal.dark")
      } else {
        themes.current("material.teal.light")
      }
      this.$vuetify.theme.dark = newVal
    },

    user(newVal) {
      if (newVal) {
        const applications = newVal.applications.map(app => app.appId)
        const features = newVal.features.map(app => app.featureId)
        const hasModules = newVal.properties.find((p) => p.propertyId === 8)?.propertyValue
        let modules = []
        if (hasModules) {
          modules = hasModules.split(',')
        }
        userflow.init('ct_y6ymcffu2bczvjnkhpuso3qyl4')
        userflow.identify(newVal.userId, {
          name: newVal.userId,
          email: newVal.userId,
          applications: applications,
          features: features,
          modules: modules,
          locale_code: this.userLocale,
          production: window.location.origin.includes('admin.entrecloud.se'),
        })
        userflow.group(newVal.company.customerNumber, {
          name: newVal.company.companyName,
          customerNumber: newVal.company.customerNumber,
          companyId: newVal.company.companyId
        })
      }
    },
    $route(to) {
      if (to.matched) {
        const title = to.matched.map(route => {
          if (route && route.path === "/company/:id") {
            return '<CompanyNamePlaceholder>'
          }
          return route?.meta?.translationKey
        }).filter(text => !!text === true)
        .map(text => text !== '<CompanyNamePlaceholder>' ? this.$t(text) : (this?.user?.company?.companyName ?? text))
        .join(' > ')
        document.title = title ? title : 'Portal Admin'
      }
    }
    // userLocale(newVal) {
    //   loadLanguageAsync(newVal)
    // }
  },
  created() {
    this.$store.dispatch('ui/initializeUserInterface')
  } 
}
</script>

<style lang="scss">
#hvd-app {
  background: var(--v-appbackground-base);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--v-secondarygrey4-base);
    border-radius: 20px;
  }
}

</style>