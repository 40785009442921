import { Property } from './Property'
/**
 * Class BooleanProperty
 *
 * @extends Property
 */
export class BooleanProperty extends Property {
  constructor(props) {
    super(props)
    this.propertyValue = props.propertyValue ? props.propertyValue.toLowerCase() : ''
  }

  get value() {
    return this.propertyValue === 0 ? '' : this.propertyValue
  }

  set value(val) {
    this.propertyValue = val === 0 ? '' : val
  }
}
