<template>
  <transition name="load-mask">
    <v-container
      v-if="!hidden"
      id="loading-mask"
      fluid
    >
      <v-row
        class="fill-height mask-row"
        align-content="center"
        justify="center"
      >
        <v-col cols="4">
          <v-progress-linear
            color="accent"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
        <v-col
          class="subtitle-1 text-center"
          cols="12"
        >
          Portal Admin <br>
          {{ env }} v{{ appVersion }}
        </v-col>
      </v-row>
    </v-container>
  </transition>
</template>

<script>
import { currentEnvironment } from '../util/tools'

export default {
  props: {
    hidden: {
      type: Boolean,
      required: true
    }
  },
  computed: {
     env(){
      let env = currentEnvironment()      
      return env !== 'production' ? env : ''
    },
    appVersion() {
      return process.env.VUE_APP_VERSION
    } 
  }
}
</script>

<style>
#loading-mask {
  z-index: 1000;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  background-color: var(--v-appbackground-base);
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask-row {
  width: 100%;
}
.load-mask-enter, .load-mask-leave-to {
  opacity: 0;
}
.load-mask-enter-to, .load-mask-leave {
  opacity: 1;
}
.load-mask-enter-active {
  transition: all .2s ease;
}
.load-mask-leave-active {
  transition: all .4s ease;
}
</style>