export class UserTokenInfo {
  constructor(props) {
    this.applications = props.applications // array
    this.authHash = props.authHash // string
    this.companies = props.companies // array
    this.company = props.company // object
    this.companyId = props.companyId // int
    this.endpoint = props.endpoint // object
    this.endpointId = props.endpointId // int
    this.features = props.features // array
    this.group = props.group // object
    this.groupId = props.groupId // int
    this.hasImage = props.hasImage // bool
    this.properties = props.properties // array
    this.timestampUTC = props.timestampUTC // string
    this.userDbId = props.userDbId // int
    this.userId = props.userId // string
    this.userName = props.userName // string
  }
}
