import adminApiClient from '../../api/admin-api-client'
import { BaseCollection } from '../BaseCollection'
import { HostingShortcut } from './HostingShortcut'

export class UserShortcutCollection extends BaseCollection {
  constructor(props) {
    super(props)

    this.collectionKey = 'shortcutId'
  }

  /**
   * @override
   * Get list of hosting shortcuts for a user
   * @returns {HostingShortcut[]}
   */
  async fetch(userId) {
    this.isLoading = true
    try {
      const response = await adminApiClient.getHostingUserShortcuts(userId, {
        Page: 1,
        PageSize: 999,
        OrderBy: this.orderBy,
        Filter: this.filter,
      })
      this.count = response.data.count
      this.items = response.data.data.map((c) => new HostingShortcut(c))
      this.isLoading = false
      return this.items
    } catch (ex) {
      this.isLoading = false
      throw new Error(ex.message)
    }
  }

  /**
   * @override
   * Connect a new hosting shortcut to a user
   * @param {Number} userId
   * @param {Number} shortcutId
   */
  async create(userId, shortcutId) {
    const param = {
      userId: userId,
      shortcutId: shortcutId,
    }
    this.isLoading = true
    try {
      let response = await adminApiClient.createHostingUserShortcut(param)

      if (response.status === 200) {
        this.isLoading = false
      } else {
        throw new Error('Failed to create shortcut')
      }
    } catch (ex) {
      this.isLoading = false
      throw ex
    }
  }

  /**
   * @override
   * Delete hosting shortcut from a user
   * @param {Number} userId
   * @param {Number} shortcutId
   */
  async delete(userId, shortcutId) {
    this.isLoading = true
    try {
      await adminApiClient.deleteHostingUserShortcut(userId, shortcutId)
      let stillInArray = this.items.findIndex((shortcut) => shortcut.shortcutId === shortcutId)
      if (stillInArray > -1) {
        this.removed = this.items[stillInArray]
        this.items.splice(stillInArray, 1)
      }
      this.isLoading = false
      return true
    } catch (ex) {
      this.isLoading = false
      throw ex
    }
  }
}
