// import { groupId, system } from './enum'
import { groupId } from './enum'
import adminApiClient from '../api/admin-api-client'

/**
 *
 *
 * Example props
 * [
 *  { "companyId": 8, "propertyId": 5, "propertyValue": "fi" }
 *  { "companyId": 8, "propertyId": 8, "propertyValue": "" }
 * ]
 *
 * Example propsDefinition
 * [
 *   {
 *    "propertyId": 5,
 *    "propertyName": "Language",
 *    "propertyType": 0,
 *    "propertyValueType": "Language",
 *    "propertyDesc": "[default]Language[sv-SE]Språk",
 *    "selection": [
 *	    {
 *        "selectionKey": "sv",
 *        "selectionValue": "[default]Sweden[sv-SE]Sverige[fi-FI]Ruotsi[no-NO]Sverige[da-DK]Sverige"
 *	    },
 *	    {
 *	       "selectionKey": "fi",
 *	       "selectionValue": "[default]Finland[fi-FI]Suomi"
 *      },
 *     ]
 *   }
 * ]
 * @deprecated
 *
 * @param {array} props - user / company props
 * @param {array} propsDefinitons - props where logged user can access
 *
 * @returns {array} - array of formatted property objects
 */
export const propertyMerger = (props, propsDefinitons) => {
  console.warn('Deprecated function propertMerger (obsolete) used!')
  if (!props || !props.length || !propsDefinitons || !propsDefinitons.length) {
    return []
  }
  const mergedProps = []
  propsDefinitons.forEach((p) => {
    const hasThisProp = props.find((prop) => prop.propertyId == p.propertyId)
    if (hasThisProp) {
      mergedProps.push({
        ...p,
        ...hasThisProp,
      })
    }
  })
  return mergedProps
}

export const currentEnvironment = () => {
  if (window.location.hostname == 'localhost') return 'development'
  if (window.location.hostname.indexOf('.staging') >= 0) return 'staging'
  else return 'production'
}
/**
 *
 * @param {*} gId
 * @returns
 */
export const userGroupIcon = (gId) => {
  return (
    {
      [groupId.PortalSuperAdmin]: 'fas fa-user-robot',
      [groupId.HostingAdmin]: 'fas fa-server',
      [groupId.PortalGlobalAdmin]: 'fas fa-globe',
      [groupId.PartnerAdmin]: 'fas fa-user-tie',
      [groupId.PortalUserAdmin]: 'fas fa-shield-alt',
      [groupId.SubdivisionAdmin]: 'fas fa-shield-alt',
      [groupId.PortalUser]: 'fas fa-user',
    }[gId] ?? 'fas fa-question-square'
  )
}

// export const systemIcon = (sId) => {
//   return (
//     {
//       [system.Entre]: 'fas fa-toolbox',
//       [system.Xpaja]: 'fas fa-tools',
//       [system.Bilanco]: 'far fa-bold',
//       [system.EntreNet]: 'fas fa-dice-d6',
//     }[sId] ?? 'fas fa-question-square'
//   )
// }

export const getCompanyByFiscalId = (fiscalId) => {
  return adminApiClient
    .getCompanyByFiscalId(fiscalId)
    .then((response) => {
      if (response.status === 200) {
        return response
      } else if (response.status === 404) {
        return false
      }
    })
    .catch((err) => {
      if (err.response.data.status === 404) {
        return false
      }
      throw err
    })
}
