<template>
  <div class="version-info">
    {{ env }} v{{ appVersion }}
  </div>
</template>
<script>
import { currentEnvironment } from '../util/tools'
export default {
  name: 'TheVersionInfo',
  
  computed: {
     env(){
      let env = currentEnvironment()      
      return env !== 'production' ? env : ''
    },
    appVersion() {
      return process.env.VUE_APP_VERSION
    } 

  }
}
</script>

<style scoped>

.version-info {
  position: fixed;
  z-index: 10;
  bottom: 2px;
  right: 10px;
  font-size: 11px;
}

</style>