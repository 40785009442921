import adminApiClient from '../../api/admin-api-client'
import { BaseCollection } from '../BaseCollection'
import { Subdivision } from './Subdivision'

/**
 * Class SubdivisionCollection
 *
 * @extends BaseCollection
 */
export class SubdivisionCollection extends BaseCollection {
  constructor(props) {
    super(props)
    this.companyId = props.companyId || 0
    this.userId = props.userId || 0
    this.collectionKey = 'subdivisionId'
  }

  setItems(data) {
    this.items = data.map((sd) => new Subdivision(sd))
  }

  /**
   * GET /api/Companies/{companyId}/querysubdivisions
   * @param {number} companyId
   * @returns
   */
  async fetch() {
    if (!this.companyId) {
      throw new Error('Company ID missing!')
    }

    this.isLoading = true

    try {
      const sdResponse = await adminApiClient.getSubdivisions(this.companyId, {
        Page: this.page,
        PageSize: this.pageSize,
        OrderBy: this.orderBy,
        Filter: this.filter,
      })
      this.loadCount++
      this.count = sdResponse.data.count
      this.items = sdResponse.data.data.map((sd) => new Subdivision(sd))
      this.isLoading = false
      return this.items
    } catch (ex) {
      this.isLoading = false
      throw ex
    }
  }
  /**
   * GET /api/Companies/{companyId}/querysubdivisions/{userDbId}
   *
   * @param {number} companyId
   * @param {number} userId
   * @returns
   */
  async fetchByUserAndCompany() {
    if (!this.companyId || !this.userId) {
      throw new Error('Company ID or user id missing!')
    }
    this.isLoading = true
    try {
      const sdResponse = await adminApiClient.getUserSubdivisions(this.companyId, this.userId)
      this.items = sdResponse.data.data.map((sd) => new Subdivision(sd))
      this.loadCount++
      this.isLoading = false
      return this.items
    } catch (ex) {
      this.isLoading = false
      throw ex
    }
  }

  async fetchById(subdivisionId) {
    this.isLoading = true
    try {
      const sdResponse = await adminApiClient.getSubdivision(this.companyId, subdivisionId)
      this.items = [new Subdivision({ companyId: this.companyId, ...sdResponse.data })]
      this.loadCount++
      this.isLoading = false
      return this.items
    } catch (ex) {
      this.isLoading = false
      throw ex
    }
  }

  /**
   * @override
   *
   * POST /api/companies/{companyId}/subdivision
   *
   * @param {Subdivision} subdivision
   * @param {int} companyId
   */
  create(subdivision) {
    if (!this.companyId) {
      throw new Error('CompanyId is not set')
    }

    const data = {
      companyId: this.companyId,
      data: {
        subdivisionName: subdivision.subdivisionName,
        externalCompanyId: subdivision.externalCompanyId, // the GUID
        externalCompanyNumber: subdivision.externalCompanyNumber, // ERP company Number
        endpointId: subdivision.endpointId,
        locate: subdivision.locale,
      },
    }
    this.isLoading = true
    subdivision.isLoading = true
    return adminApiClient
      .createSubdivision(this.companyId, data)
      .then((response) => {
        if (response.status === 200) {
          const subdivisionId = response.data
          return adminApiClient.getSubdivision(this.companyId, subdivisionId)
        } else {
          throw new Error('Failed to save subdivision')
        }
      })
      .then((response) => {
        if (response.status === 200) {
          const newSubdivision = new Subdivision({ companyId: this.companyId, ...response.data })
          this.items.push(newSubdivision)
          this.added = newSubdivision
          this.isLoading = false
          subdivision.isLoading = false
          return newSubdivision
        } else {
          throw new Error('Failed to save subdivision')
        }
      })
      .catch((ex) => {
        this.isLoading = false
        subdivision.isLoading = false
        throw ex
      })
  }

  /**
   * @override
   *
   * DELETE /api/companies/{company}/subdivision/{subdivisionId}
   *
   */
  delete(subdivisionId, companyId) {
    let sd = this.items.find((sd) => sd.subdivisionId === subdivisionId)
    sd.isLoading = true
    // TODO: delete from list
    return adminApiClient
      .deleteSubdivision(companyId, subdivisionId)
      .then((response) => {
        sd.isLoading = false
        if (response.status === 200) {
          let stillInArray = this.items.findIndex((sd) => sd.subdivisionId === subdivisionId)
          if (stillInArray > -1) {
            this.items.splice(stillInArray, 1)
          }
          this.removed = sd
          return true
        }
        throw new Error('Failed to delete')
      })
      .catch((ex) => {
        sd.isLoading = false
        throw ex
      })
  }

  /**
   * Removes user to subdivsion connection
   *
   * Note: same call is used in UserCollection
   *
   * POST /api/Users/removesubdivision
   * @param {*} userId
   * @param {*} subdivisionId
   * @returns
   */
  async removeUserSubdivisionConnection(userDbId, companyId, subdivisionId) {
    let subdivision = this.items.find((sd) => sd.subdivisionId === subdivisionId)
    subdivision.isLoading = true
    this.isLoading = true
    try {
      let response = await adminApiClient.removeUserFromSubdivision({
        userId: userDbId,
        companyId: companyId,
        SubdivisionId: subdivisionId,
        externalCompanyNumber: null,
      })
      if (response.status === 204) {
        subdivision.isLoading = false
        this.isLoading = false
        let stillInArray = this.items.findIndex((sd) => sd.subdivisionId === subdivisionId)
        if (stillInArray > -1) {
          this.items.splice(stillInArray, 1)
        }
        return true
      }
      throw new Error('Failed to remove')
    } catch (ex) {
      this.isLoading = false
      throw ex
    }
  }

  /**
   * Connects user to subdivision
   *
   * POST /api/Users/addorupdatesubdivision
   *
   * @param {*} connectionProps
   */
  async connectUserToSubdivision(connectionProps) {
    const params = {
      userId: connectionProps.user.userDbId,
      companyId: connectionProps.user.companyId,
      groupId: null,
      externalCompanyUser: connectionProps.externalCompanyUser,
      endpointId: connectionProps.endpointId !== 0 ? connectionProps.endpointId : null,
      locale: connectionProps.locale,
      subdivisionDefinition: {
        subdivisionId: connectionProps.subdivision.subdivisionId,
        externalCompanyNumber: null,
        externalCompanyId: null,
      },
    }
    connectionProps.subdivision.isLoading = true
    this.isLoading = true
    try {
      const response = await adminApiClient.connectUserToSubdivision(params)
      if (response.status === 200) {
        this.isLoading = false
        connectionProps.subdivision.isLoading = false
        this.items.push(connectionProps.subdivision)
        return true
      } else {
        throw new Error('Failed to add connection')
      }
    } catch (ex) {
      connectionProps.subdivision.isLoading = false
      this.isLoading = false
      throw ex
    }
  }
}
