import axios from 'axios'
import { IMAGE_URL } from '../util/URL.js'

const client = axios.create({
  baseURL: IMAGE_URL,
  timeout: 20000,
  headers: {
    'content-type': 'application/json',
  },
})

const imageApiClient = {
  client: client,

  setToken(token) {
    this.client.defaults.headers['Authorization'] = 'Bearer ' + token
  },

  get(resource) {
    return this.client.get('/' + resource)
  },

  post(resource, payload, opts) {
    return this.client.post('/' + resource, payload, opts)
  },
  delete(resource) {
    return this.client.delete('/' + resource)
  },

  ///ProductsetupImages
  getProductSetupLogo(resource) {
    return this.client.get('/ProductSetup/Logo/' + resource)
  },

  postProductSetupLogo(resource, payload, opts) {
    return this.client.post('/ProductSetup/Logo/' + resource, payload, opts)
  },
  deleteProductSetupLogo(resource) {
    return this.client.delete('/ProductSetup/Logo/' + resource)
  },
  getProductSetupBackground(resource) {
    return this.client.get('/ProductSetup/Background/' + resource)
  },

  postProductSetupBackground(resource, payload, opts) {
    return this.client.post('/ProductSetup/Background/' + resource, payload, opts)
  },
  deleteProductSetupBackground(resource) {
    return this.client.delete('/ProductSetup/Background/' + resource)
  },
  /**
   * @param {Tile} tile fetch all tiles from api
   */
  getTiles() {
    return this.client.get('Tiles/list')
  },
  /**
   * @param {Tile} tile create tile request
   */
  createTile(tile) {
    return this.client.post('Tiles', { data: tile })
  },
  /**
   * @param {Tile} tile delete tile with tile.id
   */
  deleteTile(tile) {
    return this.client.delete('Tiles?id=' + tile.tileId)
  },

  /**
   * Set the user image
   * @param {Number} userId The id of the user
   * @param {Blob} blob The image blob
   * @returns The network request
   */
  setUserImage(userId, blob) {
    const opts = {
      headers: {
        'content-type': 'image/png',
      },
    }
    return this.client.post('UserImage/' + userId, blob, opts)
  },

  /**
   *
   * @param {Number} userId The id of the user
   * @returns The network request
   */
  deleteUserImage(userId) {
    return this.client.delete('UserImage/' + userId)
  },

  /**
   * Set a logo for a company, association or subdivision
   * @param {String} companyId The company id
   * @param {Blob} blob The image blob
   * @param {Boolean} branchLogo Is the logo for an association
   * @param {Number} externalCompanyId FTG number from ERP
   * @returns The network request
   */
  setLogo(companyId, blob, branchLogo, externalCompanyId) {
    let opts = {
      headers: {
        'content-type': 'image/png',
      },
      params: {
        tag: branchLogo ? 'association' : null,
        subDivision: externalCompanyId > 0 ? externalCompanyId : null,
      },
    }
    return this.client.post('Logotype/' + companyId, blob, opts)
  },

  /**
   * Delete a logo from a company, association or subdivision
   * @param {String} companyId The company id
   * @param {Object} options Object containing branchLogo or externalCompanyId info
   * @returns The network request
   */
  deleteLogo(companyId, options) {
    let url = 'Logotype/' + companyId
    if (options.branchLogo) {
      url += '?tag=association&subDivision=' + options.externalCompanyId
    } else if (options.externalCompanyId) {
      url += '?subDivision=' + options.externalCompanyId
    }
    return this.client.delete(url)
  },
  postTileImage(payload, opts) {
    return this.client.post('/Tiles/Image', payload, opts)
  },
  deleteTileImage(opts) {
    return this.client.delete('/Tiles/Image', opts)
  },
}

export default imageApiClient
