<template>
  <v-dialog
    v-model="open"    
    max-width="30%"
    persistent
  >
    <v-card tile>
      <v-card-title>
        {{ $t('ConnectShortcutToUser') }}
      </v-card-title>
      <v-card-text>
        <v-form 
          ref="form"
        >
          <v-select
            v-model="selectedShortcut"
            :items="shortcutCollection.items"
            :loading="shortcutCollection.isLoading"
            item-text="name"
            item-value="shortcutId"
            :label="$t('Shortcut') + ' *'"
            :hint="$t('Shortcut') + ' *'"
            persistent-hint
            single-line
          >
            <template #item="{ item, on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-avatar
                  tile
                  left
                >
                  <img
                    max-width="20"
                    :src="'data:image/png;base64,' + item.icon"
                    alt="Icon"
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template #selection="{ item, on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-avatar
                  tile
                  left
                >
                  <img
                    max-width="20"
                    :src="'data:image/png;base64,' + item.icon"
                    alt="Icon"
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn     
          text
          @click="onHide"
        >
          {{ $t('Close') }}
        </v-btn>
        <v-btn
          color="primary"     
          class="mx-1"
          depressed          
          @click="onConnectShortcut"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { HostingShortcutCollection } from '../../../model/operations/HostingShortcutCollection'

export default {
  name: 'ConnectShortcutToUserDialog',
  props: {
    companyId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      open: false,
      selectedShortcut: null,
      shortcutCollection: new HostingShortcutCollection({ companyId: this.companyId})
    }
  },
  watch: {
    'open': function(newVal) {
      if (newVal) {
        this.shortcutCollection.page = 1
        this.shortcutCollection.pageSize = 999
        this.shortcutCollection.fetch()
        .catch((ex)=>{
          this.$alert({type: 'error', message: this.$handleError(ex) })
        })
      }
    }
  },
  methods: {
    onHide() {
      this.open = false
    },
    onConnectShortcut() {
      if (!this.$refs.form.validate() && !this.selectedShortcut) {
        return
      }    
      this.$emit('connectionselected', this.selectedShortcut)
    },
  }
}
</script>
