<template>
  <v-autocomplete
    ref="sdcombobox"
    dense
    prepend-inner-icon="far fa-search"
    :value="selectedSubdivision"
    :label="$t('Subdivision')"   
    :items="sdc.items"
    item-text="subdivisionName"
    item-value="subdivisionId"
    clearable
    :rules="required ? [v => !!v || ' '] : []"
    class="hd-autocomplete"
    @change="onSelectionChange"
  >
    <template v-slot:selection="{ item }">
      <!-- <HdSystemIcon
        :system-id="item.endpoint.systemId"  
      /> -->
      <div class="ml-2">
        {{ item.subdivisionName }} ({{ item.endpointName }})        
      </div>
    </template>
    <template v-slot:item="{ item }">
      <!-- <HdSystemIcon
        :system-id="item.endpoint.systemId"
      /> -->
      <div class="ml-2">
        {{ item.subdivisionName }} ({{ item.endpointName }})        
      </div>
    </template>
  </v-autocomplete>  
</template>
<script>
import { SubdivisionCollection } from '../../../model/subdivision/SubdivisionCollection'
// import HdSystemIcon from '../../widget/HdSystemIcon'
export default {
  name: 'HdSubdivisionSelector',

  // components: {
  //   HdSystemIcon
  // },
  props: {
    companyId: {
      required: true,
      type: Number
    },
    selectedSubdivision: {
      required: false,
      type: Number,
      default() { 
        return null
      }
    },
    required: {
      required: false,
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      sdc: new SubdivisionCollection({ companyId: this.companyId, pageSize: 300 }),
    }
  },
  mounted() {
    if(this.companyId > 0) {
      this.sdc.companyId = this.companyId
      this.sdc.fetch()
    } 
  },
  methods: {
    getValue() {
      let subdivisionId = this.$refs.sdcombobox.internalValue      
      return subdivisionId > 0 ? subdivisionId : null
    },
    validate() {
      return this.$refs.sdcombobox.validate()
    },
    onSelectionChange(e) {
      if(!e) { // clear pressed, e = undefined
        return
      }
      let sd = this.sdc.getRecordByCollectionKey(e)
      this.$emit('subdivisionselected', sd)
    },
    fetchWithId(companyId) {
      if(companyId > 0) {
        this.sdc.companyId = companyId 
        this.sdc.fetch()  
      }
    }
  }
}

</script>
<style scoped>

</style>