<template>
  <div class="d-flex-column">
    <div class="text-caption">
      {{ model.text }}
    </div>
    <v-chip-group
      v-model="model.value"
      
      :disabled="model.readOnly"
      active-class="primary--text"
      mandatory
    >
      <v-chip
        value=""
      >
        {{ $t('OptionNotSet') }}
      </v-chip>
      <v-chip
        value="false"
      >
        {{ $t('OptionOff') }}
      </v-chip>
      <v-chip
        value="true"
      >
        {{ $t('OptionOn') }}
      </v-chip>
    </v-chip-group>
  </div>
</template>
<script>
import { ComponentModel } from '../../../model/ComponentModel'

export default {
  name: 'HdTriStateField',
  props: {
		model: {
			type: ComponentModel,
			required: true,
		}
	},


}
</script>

<style scoped>
.hd-checkbox {
  padding-top: 0;
  color: #fff;
  margin-top: 0;
}
</style>
