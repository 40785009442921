const uiSettings = {
  namespaced: true,

  state: {
    darkTheme: null,
    miniMenu: null,
  },

  mutations: {
    setDarkTheme(state, useDark) {
      state.darkTheme = useDark
    },

    setMiniMenu(state, useMiniMenu) {
      state.miniMenu = useMiniMenu
    },
  },

  actions: {
    setDarkTheme(context, useDark) {
      localStorage.setItem('dark-theme', JSON.stringify(useDark))
      context.commit('setDarkTheme', useDark)
    },
    toggleMenu(context) {
      const useMiniMenu = !context.state.miniMenu
      localStorage.setItem('minimenu', JSON.stringify(useMiniMenu))
      context.commit('setMiniMenu', useMiniMenu)
    },

    initializeUserInterface(contex) {
      const darkTheme = JSON.parse(localStorage.getItem('dark-theme'))

      /**
       * Use saved theme value or browser
       * preferred
       */
      if (darkTheme !== null) {
        contex.commit('setDarkTheme', darkTheme)
      } else {
        let useDark = false
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          useDark = true
        }
        contex.commit('setDarkTheme', useDark)
      }

      const miniMenu = JSON.parse(localStorage.getItem('minimenu'))
      if (miniMenu !== null) {
        contex.commit('setMiniMenu', miniMenu)
      } else {
        contex.commit('setMiniMenu', false)
      }
    },
  },

  getters: {
    isDarkTheme(state) {
      return state.darkTheme
    },
    isMiniMenu(state) {
      return state.miniMenu
    },
  },
}

export default uiSettings
