<template>
  <div>
    <div>
      <div
        class="row my-5 px-5"
      >
        <div class="text-h5 inline-block">
          {{ $t('Shortcuts') }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          color="secondarygreen3"
          class="mx-1"
          depressed
          small
          :disabled="!user.userDbId"
          @click="onConnectionDialog"
        >
          <v-icon
            small
            class="mr-1"
          >
            far fa-link
          </v-icon>
          {{ $t('ConnectShortcut') }}
        </v-btn>
      </div>
      <v-divider
        style="margin-bottom: 20px;"
      ></v-divider>

      <v-list
        dense
      >
        <v-list-item
          v-for="shortcut in usc.items"
          :key="shortcut.shortcutId"
        >
          <v-list-item-avatar tile>
            <img
              max-width="20"
              :src="'data:image/png;base64,' + shortcut.icon"
              alt="Icon"
            >
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ shortcut.name }}</v-list-item-title>
          </v-list-item-content>
          <v-btn
            icon
            :loading="shortcut.isLoading"
            @click="deleteUserShortcut(shortcut)"
          >
            <v-icon 
              small
              color="red lighten-1"
            >
              fas fa-trash
            </v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </div>
    <ConnectShortcutToUserDialog
      ref="shortcutconnectiondialog"
      :company-id="companyId"
      @connectionselected="onAddConnection"
    />
  </div>
</template>
<script>
import { User } from '../../model/user/User'
import { UserShortcutCollection } from '../../model/operations/UserShortcutCollection'
import ConnectShortcutToUserDialog from '../form/user/ConnectShortcutToUserDialog.vue'

export default {
  name: 'UserShortcutList',
  components: {
    ConnectShortcutToUserDialog
  },
  props: {
    user: {
      type: User,
      required: true,
    },
    companyId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      usc: new UserShortcutCollection({}),
    }
  },
  watch: {
    'user.userDbId': function(newVal) {
      if(newVal > 0 && this.usc.loadCount === 0) {    
        this.usc.fetch(newVal)
        .catch((ex)=>{
          this.$alert({type: 'error', message: this.$handleError(ex) })
        })
      }
    },
  },
  methods: {    
    onConnectionDialog() {
      this.$refs.shortcutconnectiondialog.open = true
    },
    onAddConnection(shortcutId) {
      this.usc.create(this.user.userDbId, shortcutId)
      .then(() => {
        this.$refs.shortcutconnectiondialog.open = false
        this.$toast({ type:'success', message: this.$t('ConnectionAdded') })
        return this.usc.fetch(this.user.userDbId)
      })
      .catch((ex) => {
        this.$alert({ type:'error', message: this.$handleError(ex) })
      })
    },
    deleteUserShortcut(shortcut) {
      this.$confirm({
        color: 'error',  
        title: this.$t('Delete'), 
        text: this.$t('ConfirmDelete', [shortcut.name]),
        confirmButtonText: this.$t('Delete')
      })
      .then(() => {
        return this.usc.delete(this.user.userDbId, shortcut.shortcutId)
      })
      .then(() => {
        this.$toast({type: 'success', message: this.$t('DeletedItem', [this.$t('Shortcut').toLowerCase(), shortcut.name]) } )
      })
      .catch((ex) => {
        if(ex !== 'cancel') {
          this.$alert({ type:'error', message: this.$handleError(ex) })
        }
      })
    },
  },
}
</script>