<template>
  <v-autocomplete
    ref="impusersearch"
    :value="model.value"
    :label="model.text"
    :items="model.searchResults"
    dense
    clearable
    prepend-inner-icon="far fa-search"
    item-text="userId"
    item-value="userId"
    class="hd-autocomplete"
    :loading="model.isLoading"
    :filter="(i) => i"
    @keyup="onSearch"
    @change="onChange"
  >
    <template v-slot:item="{item}">    
      <div class="ml-2 mb-1">
        <div class="text-caption">
          {{ item.userId }} ({{ item.userName }})
        </div>
        <div class="font-weight-thin">
          {{ item.companyId }} {{ item.companyName }}
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
import { ImpersonateProperty } from '../../../model/property/ImpersonateProperty'


export default {
  name: 'HdImpersonateField',
  props: {
		model: {
			type: ImpersonateProperty,
			required: false,
			default(){
				return new ImpersonateProperty({})
			}
		}
	},
  data() {
    return {
      searchTimeout: null,
    }
  },
  methods: {
    onSearch() {
      
      clearTimeout(this.searchTimeout)

      this.model.items = []
      let _this = this
      
      this.searchTimeout = setTimeout(function() {    
        let search = _this.$refs.impusersearch.internalSearch

        if (_this.model.isLoading) {
          return
        }
        _this.model.searchResults = []
        _this.model.search(search)
      }, 500)      
    },
    onChange(e) {
      this.model.value = e
    },   
  }

}
</script>

<style lang="scss" scoped>

</style>
