<template>
  <v-dialog
    v-model="open"    
    max-width="600"
    style="overflow: hidden;"
    @click:outside="onCloseDialog"
  >
    <v-card>
      <v-card-title>
        {{ $t('EditImage') }}
      </v-card-title>
      <!-- <v-card-subtitle>
        {{ $t('EditImageHelp') }}
      </v-card-subtitle> -->

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          @click="move(0, -10)"
        >
          <v-icon
            small
          >
            fas fa-arrow-up
          </v-icon>
        </v-btn>
        <v-btn
          depressed
          @click="move(0, 10)"
        >
          <v-icon
            small
          >
            fas fa-arrow-down
          </v-icon>
        </v-btn>
        <v-btn
          depressed
          @click="move(-10, 0)" 
        >
          <v-icon
            small
          >
            fas fa-arrow-left
          </v-icon>
        </v-btn>
        <v-btn
          depressed
          @click="move(10, 0)" 
        >
          <v-icon
            small
          >
            fas fa-arrow-right
          </v-icon>          
        </v-btn>
        <v-divider
          vertical
          class="mx-2"
        ></v-divider>
        <v-btn
          depressed
          @click="zoom(0.1)" 
        >
          <v-icon
            small
          >
            far fa-search-plus
          </v-icon>          
        </v-btn>
        <v-btn
          depressed
          @click="zoom(-0.1)" 
        >
          <v-icon
            small
          >
            far fa-search-minus
          </v-icon>          
        </v-btn>
      </v-card-actions>
      <vue-cropper
        ref="cropper"
        :src="imageSrc"
        :aspect-ratio="aspectRatio"
        :background="false"
      ></vue-cropper>
    
      <v-card-actions
        class="p-2"
      >
        <v-spacer></v-spacer>        
        <v-btn
          depressed
          @click="open = false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          depressed
          color="primary"
          @click="onSave"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import 'cropperjs/dist/cropper.css'
import VueCropper from 'vue-cropperjs'

export default {
  name: 'HdImageCropper',
  components: {
    VueCropper,
  },
  props: {
    aspectRatio: {
      required: false,
      type: Number,
      default() {
        return 14 / 5
      }
    },
    imgWidth: {
      required: false,
      type: Number,
      default() {
        return 350
      }
    },
    imgHeight: {
      required: false,
      type: Number,
      default() {
        return 125
      }
    }
  },
  data() {
    return {
      open: false,
      imageSrc: null
    }
  },
  methods: { 
    show(imageFile) {
      this.open = true

      const reader = new FileReader()
      reader.onload = (event) => {
        this.imageSrc = event.target.result        
        this.$refs.cropper.replace(event.target.result)
      }
      reader.readAsDataURL(imageFile)

    },
    move(x, y) {
      this.$refs.cropper.move(x, y)
    },
    zoom(val) {
      this.$refs.cropper.relativeZoom(val)
    },
    onCloseDialog() {
      this.open = false
    },
    onSave() {
      this.$refs.cropper.getCroppedCanvas({
        width: this.imgWidth,
        height: this.imgHeight,
        minWidth: this.imgWidth,
        minHeight: this.imgHeight,
        maxWidth: this.imgWidth,
        maxHeight: this.imgHeight
      })
      .toBlob((blob) => {
        this.$emit('imagecropped', blob)
        this.open = false       
      })

    }
  },

  
}
</script>
<style scoped>

</style>