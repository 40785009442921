import axios from 'axios'
import { DMS_API_URL } from '../util/URL.js'

const client = axios.create({
  baseURL: DMS_API_URL,
  timeout: 20000,
  headers: {
    'content-type': 'application/json',
  },
})

const dmsApiClient = {
  client: client,

  setToken(token) {
    this.client.defaults.headers['Authorization'] = 'Bearer ' + token
  },

  /**
   *
   * @param {string} resource
   * @param {object} params exmalple { key: value }
   * @returns
   */
  get(resource, params) {
    return this.client.get('/' + resource, { params: params })
  },

  post(resource, payload) {
    return this.client.post('/' + resource, payload)
  },

  put(resource, payload) {
    return this.client.put('/' + resource, payload)
  },

  patch(resource, payload) {
    return this.client.patch('/' + resource, payload)
  },

  delete(resource) {
    return this.client.delete('/' + resource)
  },

  /**
   * @param {boolean} all false = show private only, true = show private and shared
   * @returns {Promise} list of file containers
   */
  getContainers(all) {
    let p = all ? '?visibility=all' : ''
    return this.client.get('/questionnaire/v1/containers' + p)
  },

  getContainer(containerId) {
    return this.client.get('/questionnaire/v1/containers/' + containerId)
  },

  createContainer(container) {
    return this.client.post('/questionnaire/v1/containers', container)
  },

  updateContainer(container) {
    return this.client.patch('/questionnaire/v1/containers/' + container.id, container)
  },

  deleteContainer(containerId) {
    return this.client.delete('/questionnaire/v1/containers/' + containerId)
  },

  getContainerDocuments(containerId) {
    return this.client.get(`/questionnaire/v1/containers/${containerId}/files`)
  },

  uploadDocumentToContainer(containerId, filename, document) {
    return this.client.post(`/questionnaire/v1/containers/${containerId}/files/upload?filename=${filename}`, document)
  },

  deleteDocumentFromContainer(containerId, documentId) {
    return this.client.delete(`/questionnaire/v1/containers/${containerId}/files/${documentId}`)
  },

  /**
   * @param {boolean} all false = show private only, true = show private and shared
   * @returns {Promise} list of questionnaires
   */
  getQuestionnaires(all) {
    let p = all ? '?visibility=all' : ''
    return this.client.get('/questionnaire/v1/questionnaires/' + p)
  },
  /**
   * @param {String} id The id of the questionnaire (GUID)
   * @returns {Promise} A questionnaire
   */
  getQuestionnaireById(id, expand) {
    let p = expand ? '?expand=true' : ''
    return this.client.get('/questionnaire/v1/questionnaires/' + id + p)
  },
  /**
   * @param {Object} questionnaire A minimal questionnaire object
   * @returns {Promise} A questionnaire
   */
  createQuestionnaire(questionnaire) {
    return this.client.post('/questionnaire/v1/questionnaires/', questionnaire)
  },
  /**
   * @param {Object} questionnaire The questionnaire to update
   * @returns {Promise} The updated questionnaire
   */
  updateQuestionnaire(questionnaire) {
    return this.client.patch('/questionnaire/v1/questionnaires/' + questionnaire.id, questionnaire)
  },
  /**
   * @param {String} questionnaireId The id of the questionnaire to delete
   * @returns {Promise} The id of the deleted questionnaire
   */
  deleteQuestionnaire(questionnaireId) {
    return this.client.delete('/questionnaire/v1/questionnaires/' + questionnaireId)
  },
  /**
   * @param {String} questionnaireId The id of the questionnaire
   * @param {Array} containerIds Array of string ids
   * @returns {Promise}
   */
  addContainersToQuestionnaire(questionnaireId, containerIds) {
    return this.client.post(`/questionnaire/v1/questionnaires/${questionnaireId}/containers`, containerIds)
  },
  /**
   * @param {String} questionnaireId
   * @param {String} containerId
   * @returns {Promise}
   */
  removeContainerFromQuestionnaire(questionnaireId, containerId) {
    return this.client.delete(`/questionnaire/v1/questionnaires/${questionnaireId}/containers/${containerId}`)
  },
  /**
   * @param {String} key
   * @returns {Promise}
   */
  getQuestionaireLabels(key, getTemplates) {
    return this.client.get(`/questionnaire/v1/tags/list?key=${key}&templates=${getTemplates}`)
  },

  /**
   * @returns {Promise}
   */
  getTags() {
    return this.client.get('/questionnaire/v1/tags')
  },
  /**
   * @param {Object} parameter addTagParameter
   * @returns {Promise}
   */
  addTagToEntity(parameter) {
    return this.client.post('/questionnaire/v1/tags', parameter)
  },
  /**
   * @param {String} tagId
   * @returns {Promise}
   */

  getTenantInfo(companyId) {
    return this.client.get(`/tenantmanagement/v1/tenants/getTenantInfo?companyId=${companyId}`)
  },
  getSettings(tenantId) {
    return this.client.get(`/tenantmanagement/v1/tenants/${tenantId}/getSettings`)
  },
  updateSetting(tenantId, settings) {
    return this.client.patch(`/tenantmanagement/v1/tenants/${tenantId}/updateSetting`, settings)
  },
  removeTagFromEntity(tagId) {
    return this.client.delete(`/questionnaire/v1/tags/${tagId}`)
  },

  getAnswerFoldersAndDocuments(answerId) {
    return this.client.get(`/questionnaire/v1/answers/${answerId}/documents`)
  },

  addQuestion(questionnaireId, question) {
    return this.client.post(`/questionnaire/v1/questionnaires/${questionnaireId}/questions`, question)
  },

  updateQuestion(questionnaireId, questionId, question) {
    return this.client.patch(`/questionnaire/v1/questionnaires/${questionnaireId}/questions/${questionId}`, question)
  },

  moveQuestion(questionnaireId, questionId, order) {
    return this.client.put(
      `/questionnaire/v1/questionnaires/${questionnaireId}/questions/${questionId}/move?changeOrder=` + order,
    )
  },

  deleteQuestion(questionId) {
    return this.client.delete(`/questionnaire/v1/questions/${questionId}`)
  },

  addAnswer(questionId, answer) {
    return this.client.post(`/questionnaire/v1/questions/${questionId}/answers`, answer)
  },

  updateAnswer(questionId, answerId, answer) {
    return this.client.patch(`/questionnaire/v1/questions/${questionId}/answers/${answerId}`, answer)
  },

  moveAnswer(questionId, answerId, order) {
    return this.client.put(`/questionnaire/v1/questions/${questionId}/answers/${answerId}/move?changeOrder=` + order)
  },

  deleteAnswer(answerId) {
    return this.client.delete(`/questionnaire/v1/answers/${answerId}`)
  },

  addFolders(answerId, folders) {
    return this.client.post(`/questionnaire/v1/answers/${answerId}/folders`, folders)
  },

  renameFolder(folderId, newName) {
    return this.client.put(`/questionnaire/v1/answers/folders/${folderId}/rename?folderName=` + newName)
  },

  deleteFolder(answerId, folderId) {
    return this.client.delete(`/questionnaire/v1/answers/${answerId}/folders/${folderId}`)
  },

  addDocuments(answerId, payload) {
    return this.client.post(`/questionnaire/v1/answers/${answerId}/documents`, payload)
  },

  deleteDocument(answerId, documentId) {
    return this.client.delete(`/questionnaire/v1/answers/${answerId}/documents/${documentId}`)
  },

  deleteDocumentFromFolder(folderId, documentId) {
    return this.client.delete(`/questionnaire/v1/folders/${folderId}/documents/${documentId}`)
  },

  /**
   * Get the subfolders of the specified path
   * @param {String} path The path to get subfolders for. Defaults to '/' (root folder)
   * @returns {Promise}
   */
  getPaths(path = '/') {
    return this.client.get(`/documentmanagement/v1/paths?path=${path}&depth=1&pageIndex=0&pageSize=1000`)
  },

  processQuestionnaireAsync(payload) {
    return this.client.post(`/questionnaire/v1/questionnaires/processasync/json`, payload)
  },

  getDocument(documentId) {
    return this.client.get(`/documentmanagement/v1/files/${documentId}/document`)
  },

  getLabels() {
    return this.client.get(`/documentmanagement/v1/labels/dtos`)
  },

  addLabel(name) {
    return this.client.put(`/documentmanagement/v1/labels?name=` + name)
  },

  deleteLabel(name) {
    return this.client.delete(`/documentmanagement/v1/labels?name=` + name)
  },

  addLabelToDocument(answerId, documentId, labelName) {
    return this.client.put(`/questionnaire/v1/answers/${answerId}/documents/${documentId}/label?labelName=` + labelName)
  },

  removeLabelFromDocument(answerId, documentId, labelName) {
    return this.client.delete(
      `/questionnaire/v1/answers/${answerId}/documents/${documentId}/label?labelName=` + labelName,
    )
  },

  copyQuestionnaire(questionnaireId, payload) {
    return this.client.post(`/questionnaire/v1/questionnaires/${questionnaireId}/initCopy`, payload)
  },
}

export default dmsApiClient
