import { render, staticRenderFns } from "./HdEndpointSelector.vue?vue&type=template&id=28280a68&scoped=true&"
import script from "./HdEndpointSelector.vue?vue&type=script&lang=js&"
export * from "./HdEndpointSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28280a68",
  null
  
)

export default component.exports