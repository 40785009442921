<template>
  <v-form
    ref="userform"
  >
    <v-container fluid>
      <v-row>
        <v-col>  
          <v-text-field
            v-if="showDbId"
            v-model="user.userDbId"
            disabled
            :label="$t('Id')"
            readonly
            autcomplete="off"
            dense
          ></v-text-field>
          <!-- user info, name, password, etc -->
          <v-text-field
            ref="uid"
            v-model="user.userId"
            :label="$t('CloudUser') + '*'"
            dense            
            autocomplete="off"
            :disabled="user.userDbId > 0"
            :success="unameSuccess"
            :error="unameError"
            :loading="unameLoading"
            :rules="[rules.required, rules.email]"
            @blur="onUserEmailBlur"
          ></v-text-field>
        </v-col>
        <v-col
          v-if="showDbId"
          cols="auto"
        >
          <UserImageEdit
            v-if="showAvatar"
            :user-db-id="user.userDbId"
          />
        </v-col>
      </v-row>

      <v-text-field
        ref="uname"
        v-model="user.userName"
        :label="$t('Name') + '*'"
        autcomplete="off"
        dense            
        :rules="[rules.required]"
      ></v-text-field>
 
      <HdPasswordFields
        ref="passwordField"
        :password-policy="passwordPolicyId"
        :can-be-empty="allowEmptyPassword"
      />
      <v-text-field
        v-model="user.phoneNumber"
        :label="$t('Phone')"
        :rules="[rules.phone]"
        dense            
      ></v-text-field>

      <v-switch   
        ref="active"
        v-model="user.active"
        :label="$t('Active')"
        dense
      >
      </v-switch>
    </v-container>
  </v-form>   
</template>
<script>
import { User } from '../../../model/user/User'
import HdPasswordFields from '../field/HdPasswordFields'
import UserImageEdit from './UserImageEdit'

export default {
  name: 'HdCreateEditUserForm',
  components: {
    UserImageEdit,
    HdPasswordFields,
  },
  props: {
    /**
     * User object
     */
    user: {
      type: User,
      required: false,
      default() {
        return new User( { active: true } )
      },
    },
    /**
     * password policy to use
     */
    passwordPolicyId: {
      type: Number,
      required: true,
    },
    allowEmptyPassword: {
      type: Boolean,
      required: false,
      default() { 
        return false
      }
    },        
    showDbId: {
      type: Boolean,
      required: false,
      default() { 
        return true
      }
    },
    showAvatar: {
      type: Boolean,
      required: false,
      default() { 
        return true
      }
    }

  },
  data() {
    return {
      unameError: false,
      unameSuccess: false,
      unameLoading: false,
      validFields: false,
      rules: {
        phone: v => {
          const pattern = /^[\d*#+\-() ]+$/ //Numbers and specific special characters allowed
          return (!v || pattern.test(v)) || this.$t('PhoneError')
        },
        email: v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z,_\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || this.$t('EmailAddressError')
        },
        required: v => !!v || ' '
      },
    }
  },
  methods: {
    validate() {
      let formValid = this.$refs.userform.validate()
      let passwordValid = this.$refs.passwordField.validate()
      return formValid && passwordValid
    },

    getValues() {
      return {
        password: this.$refs.passwordField.getValue(),
      }
    },
    onUserEmailBlur(e) {   
      if(this.user.userDbId > 0) {
        return
      }   
      this.loading = true
      User.isUserIdAvailable(e.target.value)
        .then((response) => {          
          if(!response.data) {
            this.unameError = false
            this.unameSuccess = true
          }
          else {
            this.unameSuccess = false
            this.unameError = true        
          }
        })
        .finally(() => {
          this.loading = false
        })      
    },
    clear() {
      this.$refs.passwordField.clear()
    }
  }
}
</script>