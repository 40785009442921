/**
 * Class ComponentCollection
 *
 * @extends BaseCollection
 */
export class ComponentCollection {
  get hasChanges() {
    return this.items.some((c) => c.dirty)
  }
}
