import { ComponentModel } from '../ComponentModel'
import { clientTranslator } from '../../i18n'
/**
 * Class representing response from API - GET /Applications
 *
 */
export class Application extends ComponentModel {
  constructor(params) {
    super(params)
    this.appId = params.appId
    this.applicationDescription = params.applicationDescription
    this.applicationIcon = params.applicationIcon
    this.applicationName = params.applicationName
    this.dependencies = params.dependencies
    this.features = params.features
    this.invoiceLogType = params.invoiceLogType
    this.isVisible = params.isVisible
    this.monthFee = params.monthFee
    this.restrictions = params.restrictions
    this.startupPrice = params.startupPrice
    this.systemId = params.systemId

    /**
     * Additional params
     * used in ApplicationToggleList.vue
     */
    this.component = 'HdToggleField'
    this.isSelected = params.isSelected !== undefined ? params.isSelected : false
  }
  /**
   * @override
   */
  get value() {
    return Boolean(this.isSelected)
  }
  /**
   * @override
   */
  set value(val) {
    this.isSelected = Boolean(val)
  }

  /**
   * @override
   */
  get text() {
    return clientTranslator(this.applicationName)
  }
}
