import axios from 'axios'
import { EMAIL_API_URL } from '../util/URL.js'

const client = axios.create({
  baseURL: EMAIL_API_URL,
  timeout: 20000,
  headers: {
    'content-type': 'application/json',
  },
})

const emailApiClient = {
  client: client,

  setToken(token) {
    this.client.defaults.headers['Authorization'] = 'Bearer ' + token
  },

  get(resource, params) {
    return this.client.get('/' + resource, { params: params })
  },

  post(resource, payload) {
    return this.client.post('/' + resource, payload)
  },

  put(resource, payload) {
    return this.client.put('/' + resource, payload)
  },

  delete(resource) {
    return this.client.delete('/' + resource)
  },

  renameCompanyEmail(companyId) {
    return this.client.post('Email/RenameCompanyEmail/' + companyId)
  },

  renameSubdivisionEmail(companyId, subdivisionId) {
    return this.client.post('Email/RenameSubdivisionEmail/' + companyId + '/' + subdivisionId)
  },

  getCompanyMailSettings(companyId) {
    return this.client.get('Email/GetCompanyMailSettings?companyId=' + companyId)
  },
  getCompanySubdivsionMailSettings(companyId, subdivisionId) {
    return this.client.get(
      'Email/GetCompanySubdivisionMailSettings?companyId=' + companyId + '&subdivisionId=' + subdivisionId,
    )
  },
  createCompanyMailSettings(mailSettings) {
    return this.client.post('Email/CreateCompanyMailSettings/', { data: mailSettings })
  },

  updateCompanyMailSettings(mailSettings) {
    return this.client.put('Email/UpdateCompanyMailSettings/', { data: mailSettings })
  },

  deleteCompanyMailSettings(companyId) {
    return this.client.delete('Email/DeleteCompanyMailSettings?companyId=' + companyId + '&subdivisionId=0')
  },
  deleteCompanySubdivisionMailSettings(companyId, subdivisionId) {
    return this.client.delete(
      'Email/DeleteCompanyMailSettings?companyId=' + companyId + '&subdivisionId=' + subdivisionId,
    )
  },
  getSignature(externalId, type) {
    return this.client.get(`Email/MailSignature?externalId=${externalId}&idType=${type}`)
  },

  createSignature(payload) {
    return this.client.post('Email/CreateMailSignature/', payload)
  },

  updateSignature(payload) {
    return this.client.put('Email/UpdateMailSignature/', payload)
  },

  deleteSignature(signatureId) {
    return this.client.delete('Email/DeleteMailSignature?signatureId=' + signatureId)
  },
}

export default emailApiClient
