import adminApiClient from '../../api/admin-api-client'
import { BaseModel } from '../BaseModel'
import { CreateEditHostingShortcutParam } from './CreateEditHostingShortcutParam'

export class HostingShortcut extends BaseModel {
  constructor(params) {
    super(params)

    this.shortcutId = params.shortcutId || 0
    this.name = params.name || ''
    this.command = params.command || ''
    this.icon = params.icon || ''
    this.iconId = params.iconId || 0
    this.commandId = params.commandId || 0

    /**
     * Save the initial state of the object for comparing changes
     */
    this._initialValues = { ...this, _initialValues: null }
  }

  /**
   * @override
   */
  async update(companyId) {
    let param = new CreateEditHostingShortcutParam(this)
    this.isLoading = true
    try {
      const response = await adminApiClient.updateHostingShortcut(companyId, param)
      this.isLoading = false
      if (response.status === 200) {
        const newShortcut = new HostingShortcut(response.data)
        this.command = newShortcut.command
        this.icon = newShortcut.icon
        return true
      }
      throw new Error('Failed to update shortcut')
    } catch (error) {
      this.isLoading = false
      this.resetInitialValues()
      throw error
    }
  }
}
