export const numberFormats = {
  'sv-SE': {
    currency: {
      style: 'currency',
      currency: 'SEK',
    },
    decimal: {
      useGrouping: true,
    },
  },
  'fi-FI': {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
  },
  'nb-NO': {
    currency: {
      style: 'currency',
      currency: 'NOK',
    },
  },
}
