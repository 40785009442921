import { groupValue } from '../util/enum'

export const AccessControlPlugin = {
  install: function (Vue) {
    /**
     * Check user access level
     */
    Vue.prototype.$accesscontrol = function () {
      const _this = this //Vue instance
      return {
        isAdmin: _this.$store.getters['user/getUserGroupLevel'] >= groupValue.PortalUserAdmin,
        isPartnerAdmin: _this.$store.getters['user/getUserGroupLevel'] >= groupValue.PartnerAdmin,
        isGlobalAdmin: _this.$store.getters['user/getUserGroupLevel'] >= groupValue.PortalGlobalAdmin,
        isSuperAdmin: _this.$store.getters['user/getUserGroupLevel'] >= groupValue.PortalSuperAdmin,
      }
    }
  },
}
