<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="30%"
    style="z-index: 10000000"
  >
    <v-card>
      <v-card-title>
        <v-icon
          class="alert-card-title-icon"
          :color="alertType"
          size="18"
        >
          {{ getIcon }}
        </v-icon>
        {{ alertType.toUpperCase() }}
      </v-card-title>
      <v-card-text>
        <span style="white-space: pre-line">
          {{ alertMessage }}
        </span>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="dismissAlert"
        >
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '../util/EventBus'

export default {
  data(){
    return {
      show: false,
      alertMessage: '',
      alertType: '',
    }
  },
  computed: {
    getIcon() {
      let icon = 'fas fa-bomb'
      switch (this.alertType) {
        case "info":
          icon = 'fas fa-info-circle'
          break
        case "warning":
          icon = 'fas fa-exclamation'
          break
        case "error":
          icon = 'far fa-exclamation-triangle'
          break
      }
      return icon
    },
    
  },
  mounted() {
    EventBus.$on('showalert', this.showAlert)
  },
  methods: {
    showAlert(alert) {
      if(alert) {
        this.alertMessage = alert.message
        this.alertType = alert.type
        this.show = true
      }
    },
    dismissAlert(){
      this.show = false
    }
  }
}
</script>

<style>
.alert-card-title-icon {
  margin-right: 10px;
}
</style>