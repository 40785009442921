<template>
  <v-fab-transition>
    <v-btn
      v-show="visible"
      key="1"
      v-scroll="onScroll"
      fab    
      fixed      
      bottom
      small
      right
      color="#009688"
      elevation="1"
      @click="onClick"
    >
      <v-icon style="color: #fff !important">
        fas fa-arrow-up
      </v-icon>
    </v-btn>
  </v-fab-transition>
</template>
<script>
export default {
  name: 'TheScrollTop',

  data() {
    return {
      visible: false
    }
  },
  methods:{
    onScroll(e) {
      this.visible = (window.pageYOffset || e.target.scrollTop || 0) > 50
    },
    onClick() {
      this.$vuetify.goTo(0)
    }
  }
}

</script>