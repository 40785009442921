import axios from 'axios'
import { INTERNAL_URL } from '../util/URL.js'

const client = axios.create({
  baseURL: INTERNAL_URL,
  timeout: 20000,
  headers: {
    'content-type': 'application/json',
  },
})

const authApiClient = {
  client: client,

  setToken(token) {
    this.client.defaults.headers['Authorization'] = 'Bearer ' + token
  },

  post(resource, payload) {
    return this.client.post('/' + resource, payload)
  },

  generateIntegrationToken(payload) {
    return this.client.post('Authentication/GenerateIntegrationToken', payload)
  },
  //Stats
  getStatLicense(params) {
    return this.client.get('Statistics/Licences', { params: params })
  },
  getStatTick(params) {
    return this.client.get('Statistics/Ticks', { params: params })
  },
  getStatGuest(params) {
    return this.client.get('Statistics/Guests', { params: params })
  },
  getAuditLogs(params) {
    return this.client.get('Audit', { params: params })
  },
  getProductSetups(params) {
    return this.client.get('ProductSetup', { params: params })
  },
  deleteProductSetup(productsetupId) {
    return this.client.delete(`ProductSetup/${productsetupId}`)
  },
  createProductSetups(productSetup) {
    return this.client.post('ProductSetup', productSetup)
  },
  updateProductSetups(productSetup) {
    return this.client.put('ProductSetup', productSetup)
  },
  getProducts(params) {
    return this.client.get('Product', { params: params })
  },
  getEnvironments(params) {
    return this.client.get('Environment', { params: params })
  },
  getCountry(params) {
    return this.client.get('Country', { params: params })
  },
}

export default authApiClient
