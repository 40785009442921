import { ComponentModel } from '../ComponentModel'
import { clientTranslator } from '../../i18n'

/**
 * Class Property
 *
 * @extends ComponentModel
 */
export class Property extends ComponentModel {
  constructor(params) {
    super(params)
    this.propertyId = params.propertyId || -1
    this.propertyName = params.propertyName || ''
    this.propertyDesc = params.propertyDesc || ''
    this.propertyType = params.propertyType || 0
    this.propertyValueType = params.propertyValueType || 'Text'
    this.selection = params.selection || []
    this.propertyValue = params.propertyValue || ''
    this.userDbId = params.userDbId || 0

    /**
     * component name, which should be rendered in the UI
     *
     * @property {string} component
     */

    this.component = this.getComponentType(this.propertyValueType)
  }

  get value() {
    return this.propertyValue
  }

  set value(val) {
    this.propertyValue = val
  }

  get formattedValue() {
    if (this.propertyValueType === 'B' && this.propertyValue !== '') {
      return this.propertyValue === 'True' ? 'On' : 'Off'
    }

    return this.propertyValue
  }

  get text() {
    return clientTranslator(this.propertyDesc)
  }
}
