import axios from 'axios'
import { ADMIN_API_URL } from '../util/URL.js'

const client = axios.create({
  baseURL: ADMIN_API_URL,
  timeout: 20000,
  headers: {
    'content-type': 'application/json',
  },
})

const adminApiClient = {
  client: client,

  setToken(token) {
    this.client.defaults.headers['Authorization'] = 'Bearer ' + token
  },

  /**
   *
   * @param {string} resource
   * @param {object} params exmalple { key: value }
   * @returns
   */
  get(resource, params) {
    return this.client.get('/' + resource, { params: params })
  },

  post(resource, payload) {
    return this.client.post('/' + resource, payload)
  },

  put(resource, payload) {
    return this.client.put('/' + resource, payload)
  },

  delete(resource) {
    return this.client.delete('/' + resource)
  },

  /**
   * Get a list of users
   * @param {Object} query A query object containing paging info and filters
   * @returns The network request
   */
  getUsers(query) {
    return this.client.get('Users/queryall', { params: query })
  },

  /**
   * Get a list of users in a company or subdivision
   * @param {Object} query A query object containing paging info and filters
   * @param {String} subdivisionId Optional subdivision id
   * @returns The network request
   */
  getCompanyUsers(query, subdivisionId) {
    const resource = subdivisionId ? 'querybysubdivision' : 'querybycompany'
    return this.client.get('Users/' + resource, { params: query })
  },

  /**
   * Check if an email address is already taken
   * @param {String} userId Email address
   * @returns The network request
   */
  userExists(userId) {
    return this.client.get('Users/' + userId + '/exists')
  },

  /**
   * Get a single user by id
   * @param {Number} userId
   * @returns The network request
   */
  getUser(userId) {
    return this.client.get('Users/' + userId)
  },

  /**
   * Create a new user
   * @param {User} newUser The user to create
   * @returns The network request
   */
  createUser(newUser) {
    return this.client.post('Users/', { data: newUser })
  },

  /**
   * Update a user
   * @param {Number} userId
   * @param {User} user The user to save
   * @returns The network request
   */
  updateUser(userId, user) {
    return this.client.put('Users/' + userId, { data: user })
  },

  /**
   * Delete a user by id
   * @param {Number} userId
   * @returns The network request
   */
  deleteUser(userId) {
    return this.client.delete('Users/' + userId)
  },

  /**
   * Connect a user to a subdivision
   * @param {Object} params Settings
   * @returns The network request
   */
  connectUserToSubdivision(params) {
    return this.client.post('Users/addorupdatesubdivision', params)
  },

  removeUserFromSubdivision(params) {
    return this.client.post('Users/removesubdivision', params)
  },

  /**
   * Get subdivisions for a company
   * @param {Number} companyId
   * @param {Object} params key-value pairs
   * @returns The network request
   */
  getSubdivisions(companyId, params) {
    return this.client.get(`Companies/${companyId}/querysubdivisions`, { params: params })
  },

  /**
   * Get the subdivisions of a user
   * @param {Number} companyId
   * @param {Number} userId
   * @returns The network request
   */
  getUserSubdivisions(companyId, userId) {
    const params = {
      Filter: 'implicit=false',
    }
    return this.client.get(`Companies/${companyId}/querysubdivisions/${userId}`, { params: params })
  },

  getSubdivision(companyId, subdivisionId) {
    return this.client.get(`Companies/${companyId}/subdivision/${subdivisionId}`)
  },

  getSSOSettings(companyId) {
    return this.client.get(`Companies/SSOSetting/${companyId}`)
  },
  createSSOSettings(data) {
    return this.client.post(`Companies/SSOSetting/`, { data: data })
  },
  updateSSOSettings(data) {
    return this.client.put(`Companies/SSOSetting/`, { data: data })
  },
  deleteSSOSettings(companyId) {
    return this.client.delete(`Companies/SSOSetting/${companyId}`)
  },

  createSubdivision(companyId, data) {
    return this.client.post(`Companies/${companyId}/subdivision`, data)
  },

  updateSubdivision(companyId, subdivisionId, data) {
    return this.client.put(`Companies/${companyId}/subdivision/${subdivisionId}`, data)
  },

  deleteSubdivision(companyId, subdivisionId) {
    return this.client.delete(`Companies/${companyId}/subdivision/${subdivisionId}`)
  },

  getLicenceLogs(companyId, params) {
    return this.client.get('Licences/licenselog/' + companyId, { params: params })
  },

  getLicenceOverview(companyId, subdivisionId) {
    return this.client.get(
      `Companies/licenseoverview/${companyId}${
        subdivisionId && subdivisionId !== -1 ? `/subdivision/${subdivisionId}` : ''
      }`,
    )
  },

  getEndpoints(params) {
    return this.client.get('Endpoints/query', { params: params })
  },

  getEndpoint(endpointId) {
    return this.client.get(`Endpoints/${endpointId}`)
  },
  getEndpointInsight(endpointId) {
    return this.client.get(`Endpoints/${endpointId}/applicationinsight`)
  },

  createEndpoint(data) {
    return this.client.post('Endpoints/', data)
  },

  updateEndpoint(endpointId, data) {
    return this.client.put(`Endpoints/${endpointId}`, data)
  },

  deleteEndpoint(endpointId) {
    return this.client.delete(`Endpoints/${endpointId}`)
  },

  getCompanies(params) {
    return this.client.get('Companies/querywithadminrights', { params: params })
  },
  getCompanyApikey(companyId) {
    return this.client.get(`Companies/${companyId}/apikey`)
  },
  getCompany(companyId) {
    return this.client.get(`Companies/${companyId}`)
  },

  getCompanyByFiscalId(fiscalId) {
    return this.client.get(`Companies/GetCompanyByFiscalID/${fiscalId}`)
  },

  createCompany(data) {
    return this.client.post('Companies/', data)
  },

  updateCompany(companyId, data) {
    return this.client.put(`Companies/${companyId}`, data)
  },

  deleteCompany(companyId) {
    return this.client.delete(`Companies/${companyId}`)
  },

  getTranslationsLanguages() {
    return this.client.get(`Translations/languages`)
  },

  getTranslationsDictionaries() {
    return this.client.get(`Translations/dictionaries`)
  },

  getTranslations(dictionaryId, params, signal) {
    return this.client.get(`Translations/${dictionaryId}`, { params: params, signal })
  },

  getTranslation(dictionaryId, key) {
    return this.client.get(`Translations/${dictionaryId}/${key}`)
  },

  addTranslations(dictionaryId, data) {
    return this.client.post(`Translations/${dictionaryId}`, data)
  },

  updateTranslations(dictionaryId, data) {
    return this.client.put(`Translations/${dictionaryId}`, data)
  },

  deleteTranslations(dictionaryId, keys) {
    return this.client.delete(`Translations/${dictionaryId}`, {
      data: keys,
      headers: { 'content-type': 'application/json' },
    })
  },

  getHostingIcons(params) {
    return this.client.get(`Hosting/Icons`, { params: params })
  },
  deleteHostingIcon(id) {
    return this.client.delete(`Hosting/RemoveIcon?iconId=${id}`)
  },
  createHostingIcon(blob) {
    let opts = {
      headers: {
        'content-type': 'image/x-icon',
      },
    }
    return this.client.post(`Hosting/UploadIcon`, blob, opts)
  },

  getHostingCommands(params) {
    return this.client.get(`Hosting/Commands`, { params: params })
  },
  getHostingCommandsExtended(params) {
    return this.client.get(`Hosting/ListCommands`, { params: params })
  },
  createHostingCommand(params) {
    return this.client.post(`Hosting/CreateCommand`, { cmdVM: params })
  },
  updateHostingCommand(params) {
    return this.client.put(`Hosting/UpdateCommand`, { cmdVM: params })
  },
  deleteHostingCommand(id) {
    return this.client.delete(`Hosting/RemoveCommand?cmdId=${id}`)
  },

  getHostingShortcuts(companyId, params) {
    return this.client.get(`Hosting/Shortcuts/${companyId}`, { params: params })
  },

  createHostingShortcut(companyId, data) {
    return this.client.post(`Hosting/Shortcuts/${companyId}`, data)
  },

  updateHostingShortcut(companyId, data) {
    return this.client.put(`Hosting/Shortcuts/${companyId}`, data)
  },

  deleteHostingShortcut(companyId, shortcutId) {
    return this.client.delete(`Hosting?companyid=${companyId}&shortcutId=${shortcutId}`)
  },

  getHostingUserShortcuts(userId, params) {
    return this.client.get(`Hosting/UsersShortcuts/${userId}`, { params: params })
  },

  createHostingUserShortcut(data) {
    return this.client.post(`Hosting/CreateUserShortcut`, data)
  },

  deleteHostingUserShortcut(userId, shortcutId) {
    return this.client.delete(`Hosting/RemoveUserShortcut?userId=${userId}&shortcutId=${shortcutId}`)
  },

  changeUserCompany(data) {
    return this.put('Users/ChangeUserCompanies', data)
  },
  //Licences
  getApplications(params) {
    return this.client.get('Applications/Filter/', { params: params })
  },

  updateApplication(appId, payload) {
    return this.client.put(`Applications/${appId}`, payload)
  },

  createApplication(payload) {
    return this.client.post('Applications', payload)
  },

  deleteApplication(appId) {
    return this.client.delete(`Applications/${appId}`)
  },

  //Features
  getFeatures(params) {
    return this.client.get('Features', { params: params })
  },

  updateFeature(featureId, description) {
    return this.client.put(`Features/ChangeFeatureDescription?id=${featureId}&description=${description}`)
  },

  createFeature(name, tag, description) {
    const post = `Features?name=${name}${tag ? '&tag=' + tag : ''}${description ? '&description=' + description : ''}`
    return this.client.post(post)
  },

  getLicenseGroups(params) {
    return this.client.get('LicenseGroups', { params: { ...params, includeLicenses: true } })
  },

  createLicenseGroup(payload) {
    return this.client.post('LicenseGroups', payload)
  },

  updateLicenseGroup(payload) {
    return this.client.put(`LicenseGroups`, payload)
  },

  deleteLicenseGroup(id) {
    return this.client.delete(`LicenseGroups/${id}`)
  },
}

export default adminApiClient
