<template>
  <v-switch
    v-model="model.value"
    :label="model.text "    
    :disabled="model.readOnly"
    dense
    class="hd-checkbox"
  >
  </v-switch>
</template>
<script>
import { ComponentModel } from '../../../model/ComponentModel'

export default {
  name: 'HdToggleField',
  props: {
		model: {
			type: ComponentModel,
			required: false,
      default() {
        return new ComponentModel()
      }			
		}
	},

  watch: {
    'model.value': function() {     
      this.$emit('valuechange', this.model)
    }
  },

}
</script>

<style scoped>
.hd-checkbox {
  padding-top: 0;
  color: #fff;
   margin-top: 0;
}
</style>
