import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import HDthemes from './themes/hvd-theme'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    // dark: true,
    themes: HDthemes,
  },
})
