import axios from 'axios'
import { RSS_URL } from '../util/URL'

const client = axios.create({
  baseURL: RSS_URL,
  timeout: 20000,
  headers: {
    'content-type': 'application/json',
  },
})

const rssApiClient = {
  client: client,

  setToken(token) {
    this.client.defaults.headers['Authorization'] = 'Bearer ' + token
  },
  get(resource) {
    return this.client.get('/' + resource)
  },
  post(payload, resource, opts) {
    return this.client.get('/' + payload, resource, opts)
  },
  delete(resource) {
    return this.client.delete('/' + resource)
  },
  put(resource, payload) {
    return this.client.put('/' + resource, payload)
  },
  /**
   * @param {Rss} post create RSS post request
   */
  createRssPost(post) {
    return this.client.post('Posts', { data: post })
  },
  /**
   * @param {Rss} post update RSS post request
   */
  UpdateRssPost(post) {
    return this.client.put('Posts?id=' + post.id, { data: post })
  },
  /**
   * @param {Rss} post delete RSS post request
   */
  deleteRssPost(post) {
    return this.client.delete('Posts?id=' + post.id)
  },
  /**
   * @param {Rss} post fetch all the RSS posts from backend request
   */
  getRssPosts() {
    return this.get('Posts')
  },
}

export default rssApiClient
