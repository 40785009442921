import { USER_IMAGE_URL } from '../../util/URL'
import { UserTokenInfo } from '../../model/authuser/UserTokenInfo'
/**
 *
 * State for logged in user
 *
 */
const user = {
  namespaced: true,

  state: {
    // Logged in user id
    dbId: null,
    // Object with info about users current context
    tokenInfo: null,
    // locale set for user
    locale: null,
    // properties user has access and can change.
    properties: null,
    // applications user has access and can change.
    applications: null,
    // user groups available user to select
    groups: null,
    // password policies available user to select
    passwordPolicies: null,
    // company categories available user to select
    companyCategories: null,
    // company that "owns" user
    userOriginalCompany: null,
    // account used for impersonating other users
    impersonateAccount: null,
    userImageCacheBuster: Date.now(),
    hideCompanyListLink: false,
    // locales which user can access and set to companies / subdivisions
    locales: null,
  },
  mutations: {
    setUserId(state, id) {
      state.dbId = id
    },
    setUserTokenInfo(state, payload) {
      state.tokenInfo = new UserTokenInfo(payload)
    },
    setUserLocale(state, locale) {
      state.locale = locale
    },
    setUserProperties(state, payload) {
      state.properties = payload
    },
    setUserApplications(state, payload) {
      state.applications = payload
    },
    setUserGroups(state, payload) {
      state.groups = payload
    },
    setPasswordPolicies(state, payload) {
      state.passwordPolicies = payload
    },
    setCompanyCategories(state, payload) {
      state.companyCategories = payload
    },
    setUserOriginalCompany(state, payload) {
      state.userOriginalCompany = payload
    },
    setImpersonateAccount(state, payload) {
      state.impersonateAccount = payload
    },
    setCacheBuster(state) {
      state.userImageCacheBuster = Date.now()
    },
    setHideCompanyListLink(state, payload) {
      state.hideCompanyListLink = payload
    },
    setLocales(state, payload) {
      state.locales = payload
    },
  },
  actions: {
    setUserTokenInfo(context, payload) {
      context.commit('setUserTokenInfo', payload)
      let langProp = payload.properties.find((prop) => prop.propertyId === 5)
      let lang = langProp ? langProp.propertyValue.toLowerCase() : 'en'
      let countryCode = payload.company.countryCode
      let locale = lang + '-' + countryCode
      context.commit('setUserLocale', locale)
    },
    setUserProperties(context, payload) {
      context.commit('setUserProperties', payload)
    },
    setUserApplications(context, payload) {
      context.commit('setUserApplications', payload)
    },
    setUserGroups(context, payload) {
      context.commit('setUserGroups', payload)
    },
    setPasswordPolicies(context, payload) {
      context.commit('setPasswordPolicies', payload)
    },
    setCompanyCategories(context, payload) {
      context.commit('setCompanyCategories', payload)
    },
    setUserOriginalCompany(context, payload) {
      context.commit('setUserOriginalCompany', payload)
    },
    setImpersonateAccount(context, payload) {
      if (payload) {
        localStorage.setItem('impersonateAccount', JSON.stringify(payload))
      } else {
        localStorage.removeItem('impersonateAccount')
      }
      context.commit('setImpersonateAccount', payload)
    },
    setCacheBuster(context) {
      context.commit('setCacheBuster')
    },
    setHideCompanyListLink(context, payload) {
      context.commit('setHideCompanyListLink', payload)
    },
    setLocales(context, payload) {
      context.commit('setLocales', payload)
    },
  },
  getters: {
    getAvatar(state) {
      return USER_IMAGE_URL + state.dbId + '?t=' + state.userImageCacheBuster
    },
    getUserContextProperties(state) {
      return state.properties.filter((p) => p.propertyType < 2)
    },
    getUserApplications(state) {
      return state.applications
    },
    getCompanyContextProperties(state) {
      return state.properties.filter((p) => p.propertyType == 0 || p.propertyType == 3)
    },
    getEndpointContextProperties(state) {
      return state.properties.filter((p) => p.propertyType == 0 || p.propertyType == 2)
    },
    getUserGroupLevel(state) {
      return state.tokenInfo.group.groupValue
    },
    getUserCompany(state) {
      return state.tokenInfo.companyId
    },
    getUserCompanyLanguage(state) {
      const localesList = state.locales
      let userLanguage = state.tokenInfo.properties?.find((p) => p.propertyId === 5)
      let userLocalLang
      if (userLanguage === undefined) {
        const fallbackDefaultLanguage = state.tokenInfo.company.locale
        userLocalLang = localesList.filter((language) => language.code === fallbackDefaultLanguage)
      } else {
        userLocalLang = localesList.filter((obj) => obj.code === userLanguage.propertyValue)
      }
      return userLocalLang[0]
    },
    getImpersonateAccount(state) {
      return state.impersonateAccount
    },
    hasTestModule(state) {
      let modules = state.tokenInfo.properties?.find((p) => p.propertyId === 8)

      return modules?.propertyValue?.includes('test')
    },
    hasModule(state) {
      return (module) => {
        let modules = state.tokenInfo.properties?.find((p) => p.propertyId === 8)
        return modules?.propertyValue?.includes(module)
      }
    },
    hasProperty(state) {
      return (property) => {
        let prop = state.tokenInfo.properties?.find((p) => p.propertyId === property)
        return !!prop?.propertyValue
      }
    },
  },
}

export default user
