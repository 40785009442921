import axios from 'axios'
import { AUTH_SERVICE_URL } from '@/util/URL.js'

const client = axios.create({
  baseURL: AUTH_SERVICE_URL,
  timeout: 20000,
  headers: {
    'content-type': 'application/json',
  },
})

const authApiClient = {
  client: client,

  setToken(token) {
    this.client.defaults.headers['Authorization'] = 'Bearer ' + token
  },

  get(resource) {
    return this.client.get('/' + resource)
  },

  post(resource, payload) {
    return this.client.post('/' + resource, payload)
  },
  delete(resource) {
    return this.client.delete('/' + resource)
  },

  getUserTokenInfo() {
    return this.client.post('Authentication/usertokeninfo')
  },

  getCompanyToken(apikey, externalcompanyid) {
    return this.client.post('Authentication/authenticatecompany', {
      apiKey: apikey,
      externalCompanyId: externalcompanyid,
    })
  },

  getCompanyTokenInfo() {
    return this.client.post('Authentication/companytokeninfo')
  },

  is2FAEnabled(userId) {
    return this.client.get('otp/enabled?userDbId=' + userId)
  },

  remove2FA(userId) {
    return this.client.delete('otp/' + userId)
  },
}

export default authApiClient
