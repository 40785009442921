import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './modules/authentication'
import userModule from './modules/user'
import companyModule from './modules/company'
import uiSettingsModule from './modules/ui-settings'
import authApiClient from '../api/auth-api-client'
import adminApiClient from '../api/admin-api-client'
import { groupValue } from '../util/enum'
import { loadLanguageAsync } from '../i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
  },
  mutations: {
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },
  },
  actions: {
    async init(context) {
      try {
        context.commit('setIsLoading', true)

        let userInfo = await authApiClient.getUserTokenInfo()

        if (userInfo.data && userInfo.data.group.groupValue >= groupValue.PortalUserAdmin) {
          context.dispatch('user/setUserTokenInfo', userInfo.data)

          let fallBack = navigator.language ? navigator.language.split('-')[0] : 'en'
          let langProp = userInfo.data.properties.find((prop) => prop.propertyId === 5)
          let lang = langProp ? langProp.propertyValue.toLowerCase() : fallBack
          await loadLanguageAsync(lang)
          // TODO: Cahche

          let userApps = await adminApiClient.get('Applications')
          let userProps = await adminApiClient.get('Properties')
          let userGroups = await adminApiClient.get('Groups')
          let passwordPolicies = await adminApiClient.get('PasswordPolicies')
          let locales = await adminApiClient.getTranslationsLanguages()
          context.dispatch('user/setUserProperties', userProps.data.results)
          context.dispatch('user/setUserApplications', userApps.data.results)
          context.dispatch('user/setUserGroups', userGroups.data.results)
          context.dispatch('user/setPasswordPolicies', passwordPolicies.data.results)
          context.dispatch('user/setLocales', locales.data)

          if (userInfo.data.group.groupValue >= groupValue.PartnerAdmin) {
            let companyCategories = await adminApiClient.get('CompanyCategories')
            context.dispatch('user/setCompanyCategories', companyCategories.data.results)
            context.dispatch('user/setImpersonateAccount', JSON.parse(localStorage.getItem('impersonateAccount')))
            const currentUser = userInfo.data.userId
            const userResult = await adminApiClient.getUsers({
              Page: 0,
              PageSize: 42,
              Filter: 'userId=' + currentUser + '/i',
              orderBy: 'userId asc',
            })
            if (userResult.data.count === 1) {
              const userCompany = {
                companyId: userResult.data.data[0].companyId,
                companyName: userResult.data.data[0].companyName,
              }
              context.dispatch('user/setUserOriginalCompany', userCompany)
            }
          }

          context.commit('auth/setIsLoggedIn', true)
          //get token and set the user dbId
          const token = context.rootState.auth.token
          const user = JSON.parse(atob(token.split('.')[1]))
          context.commit('user/setUserId', user.UserDbId)

          return true
        } else {
          throw new Error('failed initializing')
        }
      } catch (ex) {
        console.log(ex)
        context.dispatch('auth/logout')
      } finally {
        context.commit('setIsLoading', false)
      }
    },
  },

  modules: {
    auth: authModule,
    user: userModule,
    ui: uiSettingsModule,
    company: companyModule,
  },
})
