<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col 
        cols="4" 
        class="text-h6"
      >
        {{ title }}
      </v-col>
      <v-col
        cols="8"
        class="d-flex"
      >
        <slot>
        </slot>
      </v-col>
    </v-row>  
  </v-container>
</template>
<script>
export default {
  name:'HdPageTitleTools',
  props: {
    title: {
      type: String,
      required: false,
      default() {
        return ''
      }
    }
  }
}
</script>
<style scoped>

</style>