const company = {
  namespaced: true,

  state: {
    company: null,
  },

  mutations: {
    setCompany(state, newCompany) {
      state.company = newCompany
    },
  },

  actions: {
    setCurrentCompany(context, newCompany) {
      context.commit('setCompany', newCompany)
    },
  },

  getters: {
    getCurrentCompany(state) {
      return state.company
    },
  },
}

export default company
