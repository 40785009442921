export const ErrorHandlerPlugin = {
  install: (Vue) => {
    /**
     * Returns error message
     *
     * @param {Error} ex
     * @returns {string}
     */
    Vue.prototype.$handleError = (ex) => {
      if (ex.response && ex.response.data) {
        let msg = ex.response.data.status + ' ' + ex.response.data.title + '\n'
        if (ex.response.data.errors) {
          // errors is an object
          Object.keys(ex.response.data.errors).forEach((k) => {
            msg += ex.response.data.errors[k].join('\n')
            msg += '\n'
          })
        }
        if (ex.response.data?.detail) {
          msg += ex.response.data.detail
        }
        return msg
      }

      return ex
    }
  },
}
