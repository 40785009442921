import Vue from 'vue'
import HdCircleLoader from './components/HdCircleLoader'
import HdContainer from './components/layout/HdContainer'
import HdImplicitToggleButton from './components/button/HdImplicitToggleButton'
import HdConfirmModal from './components/HdConfirmModal'
import HdPageTitleTools from './components/layout/HdPageTitleTools'
import HdIconButton from './components/button/HdIconButton'
import HdCustomizableConfirmModal from './components/HdCustomizableConfirmModal'

Vue.component('HdCircleLoader', HdCircleLoader)
Vue.component('HdContainer', HdContainer)
Vue.component('HdImplicitToggleButton', HdImplicitToggleButton)
Vue.component('HdConfirmModal', HdConfirmModal)
Vue.component('HdCustomizableConfirmModal', HdCustomizableConfirmModal)
Vue.component('HdPageTitleTools', HdPageTitleTools)
Vue.component('HdIconButton', HdIconButton)
