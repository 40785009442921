import adminApiClient from '../../api/admin-api-client'

import { Property } from './Property'
/**
 * Class ImpersonateProperty
 *
 * @extends Property
 */
export class ImpersonateProperty extends Property {
  constructor(props) {
    super(props)

    this.searchResults = props.propertyValue
      ? [
          {
            userId: props.propertyValue,
          },
        ]
      : []
  }

  set value(val) {
    this.propertyValue = val
  }
  get value() {
    return this.propertyValue
  }
  search(search) {
    if (!search) {
      return
    }
    this.isLoading = true

    const params = {
      Page: 0,
      PageSize: 42,
      Filter: `userId=*${search}|userName=*${search}/i`,
      orderBy: 'userId asc',
    }

    adminApiClient
      .getUsers(params)
      .then((response) => {
        this.searchResults = response.data.data
      })
      .finally(() => {
        this.isLoading = false
      })
  }
}
