<template>
  <v-dialog
    v-model="open"    
    max-width="30%"
    persistent
  >
    <v-card tile>
      <v-card-title>
        {{ $t('ConnectSubdivisionToUser') }}
      </v-card-title>
      <v-card-text>
        <v-form 
          ref="form"
        >
          <HdSubdivisionSelector
            ref="subdivisionselect"
            :company-id="user.companyId"
            required          
            @subdivisionselected="onSubdivisionSelected"
          />
          <v-text-field
            ref="externaluser"
            v-model="externalUser"
            :label="$t('AdminExtEntreUser')"
            :rules="[v => !!v || ' ']"
          >
          </v-text-field>
          <a
            v-if="$accesscontrol().isGlobalAdmin && !showAdvancedSettings"
            @click="()=>{ showAdvancedSettings = true }"
          >{{ $t('AdvancedSettings') }}</a>
          <HdEndpointSelector
            v-if="showAdvancedSettings"
            v-show="$accesscontrol().isGlobalAdmin"
            ref="endpoint"
            :company-id="user.companyId"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn     
          text
          @click="onHide"
        >
          {{ $t('Close') }}
        </v-btn>
        <v-btn
          color="primary"     
          class="mx-1"
          depressed          
          :loading="selectedSubdivision.isLoading"
          @click="onConnectUserToSubdivision"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Subdivision } from '../../../model/subdivision/Subdivision'
import { User } from '../../../model/user/User'
import HdSubdivisionSelector from '../../form/field/HdSubdivisionSelector.vue'
import HdEndpointSelector from '../field/HdEndpointSelector'

export default {
  name: 'ConnectSubdivisionToUserDialog',
  components: {
    HdSubdivisionSelector,
    HdEndpointSelector
  },
  props: {
    user: {
      type: User,
      required: true,
    }
  },
  data() {
    return {
      open: false,
      selectedSubdivision: new Subdivision({}),
      externalUser: '',
      showAdvancedSettings: false,
    }
  },
  methods: {
    onHide() {
      this.showAdvancedSettings = false
      this.open = false
    },
    onConnectUserToSubdivision() {
      if (!this.$refs.form.validate()) {
        return
      }    
      const connectionObject = {
        subdivision: this.selectedSubdivision,
        user: this.user,
        endpointId: this.$refs.endpoint ? this.$refs.endpoint.getValue() : null,
        externalCompanyUser: this.externalUser,
      }
      this.$emit('connectionselected', connectionObject)
      // this.open = false
    },

    onSubdivisionSelected(subdivision) {      
      this.selectedSubdivision = subdivision
    },
    
  }
}
</script>
