export default {
  /**
   * Hantverksdata style guide colors
   *
   */
  primarygreen: '#07FFBA',
  secondarygreen1: '#ADFFE2',
  secondarygreen2: '#72FFD1',
  secondarygreen3: '#00E5AE',
  secondarygreen4: '#00CCA5',
  secondarygreen5: '#00AA91',

  secondarygrey1: '#EAF4F4',
  secondarygrey2: '#DBEDED',
  secondarygrey3: '#AFD6D3',
  secondarygrey4: '#87B7B7',
  secondarygrey5: '#5E9E9E',

  white: '#f6f6f6',
  black: '#111',
  black2: '#272727',
  darkbackground: '#334C4D',

  warning: '#FFA726',
  error: '#ff4a4a',
  success: '#00CCA5',
  info: '#29B6F6',
}
