import { ComponentCollection } from '../ComponentCollection'
import { Application } from './Application'

/**
 * Class ApplicationCollection
 *
 * @extends ComponentCollection
 */
export class ApplicationCollection extends ComponentCollection {
  constructor(data) {
    super()
    this.setItems(data)
  }

  setItems(data) {
    this.items = data.map((a) => new Application(a))
    this.initDependencies()
  }

  getEnabledApplications() {
    return this.items.filter((a) => a.isSelected)
  }

  initDependencies() {
    this.items.forEach((app) => {
      if (app.isSelected) {
        this.handleDependencies(app)
      }
    })
  }

  /**
   *
   * @param {Application} app
   */
  handleDependencies(app) {
    if (app.dependencies.length) {
      app.dependencies.forEach((d) => {
        let found = this.items.find((a) => a.appId == d.dependentOnAppId)

        let anotherAppWDependency = false

        if (!app.value) {
          anotherAppWDependency = this.getEnabledApplications().find((e) =>
            e.dependencies.some((ed) => ed.dependentOnAppId === found.appId),
          )
        }
        if (anotherAppWDependency) {
          return
        }

        if (found) {
          found.value = app.value
          found.readOnly = app.value
        }
      })
    }
  }
}
