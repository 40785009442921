import 'devextreme/dist/css/dx.common.css'
/**
 * https://js.devexpress.com/Documentation/18_2/Guide/Themes_and_Styles/Predefined_Themes/
 * These styles are defined in public/index.html for switching to work
 */
//import 'devextreme/dist/css/dx.material.teal.dark.compact.css'
//import 'devextreme/dist/css/dx.material.teal.light.compact.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@fortawesome/fontawesome-pro/css/all.min.css'
import './assets/global.css'
import { i18n } from './i18n'
import './global-components'
import { ErrorHandlerPlugin } from './plugins/error-handler'
import { AccessControlPlugin } from './plugins/access-control'
import { ToastPlugin } from './plugins/toast'
import { ConfirmPlugin } from './plugins/confirm'

Vue.config.productionTip = false

Vue.use(ErrorHandlerPlugin)
Vue.use(AccessControlPlugin)
Vue.use(ToastPlugin)
Vue.use(ConfirmPlugin)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
