import store from '../../store/index.js'

export const accessGuard = (to, from, next) => {
  const userGroupLevel = store.getters['user/getUserGroupLevel']
  const requiresModules = !!to.meta.requiredModules
  let hasRequiredModules = requiresModules
    ? to.meta.requiredModules.every((module) => store.getters['user/hasModule'](module))
    : true
  const requiresProperty = !!to.meta.requiredProperty
  let hasRequiredProperty = requiresProperty ? store.getters['user/hasProperty'](to.meta.requiredProperty) : true
  //Check for drift on company when accessing it
  const currentCompany = store.getters['company/getCurrentCompany']
  const hasDrift =
    !to.path.includes('shortcuts') ||
    (currentCompany && currentCompany.properties.findIndex((prop) => prop.propertyId === 11 && prop.propertyValue) > -1)

  if (userGroupLevel < to.meta.requiredAccessLevel || !hasRequiredModules || !hasRequiredProperty || !hasDrift) {
    next({ name: 'Error404Page' })
  } else {
    next()
  }
}
