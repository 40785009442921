<template>
  <div>
    <v-text-field   
      v-model="model.value"   
      :label="model.text"
      :error="!model.isValid"
      dense
    >
    </v-text-field>
  </div>
</template>
<script>
import { ComponentModel } from '../../../model/ComponentModel'

export default {
  name: 'HdTextField',
  props: {
		model: {
			type: ComponentModel,
			required: true	
		},
	},
  
  // watch: {
  //   'model.value': function(val) {
  //     console.log(val)
  //   }
  // }
}
</script>

<style lang="scss" scoped>

</style>
