import { BaseModel } from '../BaseModel'
export class PasswordModel extends BaseModel {
  constructor(params) {
    super(params)
    this.password = params.password || ''
    this.repeatPassword = params.repeatPassword || ''
    this.isValid = params.isValid || true
    this.errorMessages = []
    this.passwordPolicy = params.passwordPolicy || {}
  }

  /**
   * @override
   */
  validate(canBeEmpty) {
    // no changes
    if (!this.dirty && canBeEmpty) {
      this.errorMessages = []
      this.isValid = true
      return true
    }
    // Check for min length
    let isSamePassword = true
    let hasErrors = false
    const minLength = this.passwordPolicy.minimumLength

    // Maybe can reformat it better

    if (this.password.length < minLength) {
      hasErrors = true
      this.isValid = false
    }

    // Check for special characters
    if (this.passwordPolicy.specialCharacters) {
      const characters = new RegExp('[' + this.passwordPolicy.characters.replace(/[.*+?!^${}()|[\]\\]/g, '\\$&') + ']')
      if (this.password.match(characters) == null) {
        hasErrors = true
      }
    }

    // Check for numbers
    if (this.passwordPolicy.numbers) {
      const number = /\d/
      if (!number.test(this.password)) {
        hasErrors = true
      }
    }

    // Match passwords
    if (!hasErrors && this.password != this.repeatPassword) {
      hasErrors = true
      isSamePassword = false
    }

    if (hasErrors) {
      if (!isSamePassword) {
        // const translations = `
        //   [default]The new passwords do not match
        //   [sv-SE]Angivna lösenord överensstämmer inte
        //   [fi-FI]Salasanat poikkeavat toisistaan
        //   [no-NO]Angitte passord stemmer ikke overens
        //   [da-DK]Den angivne adgangskode stemmer ikke`
        this.errorMessages = ['MissmatchPassword']
      } else {
        this.errorMessages = [this.passwordPolicy.name]
      }
      this.isValid = false
    } else {
      this.errorMessages = []
      this.isValid = true
      this.isSamePassword = true
    }
  }
}
