import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import { I18N_URL } from '@/util/URL'
import { dateTimeFormats } from '@/locales/formats/date-formats'
import { numberFormats } from '@/locales/formats/number-formats'
Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export const i18n = new VueI18n({
  locale: navigator.language,
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  dateTimeFormats,
  numberFormats,
  silentTranslationWarn: true,
})

const loadedLanguages = []
/**
 * Sets ui lang
 *
 * @param {string} locale
 * @param {boolean} usePreloadedLang
 * @returns {string} locale string
 */
function setI18nLanguage(locale, usePreloadedLang = false) {
  if (!i18n.dateTimeFormats[locale]) {
    let cultureDateFormatKey = Object.keys(i18n.dateTimeFormats).find((x) => {
      return x.includes(locale.split('-')[1])
    })
    i18n.setDateTimeFormat(locale, i18n.dateTimeFormats[cultureDateFormatKey])
    i18n.setNumberFormat(locale, i18n.numberFormats[cultureDateFormatKey])
  }
  let localeToUse = usePreloadedLang ? locale.substring(0, 2) : locale

  i18n.locale = localeToUse === 'nb' ? 'no' : localeToUse // 'nb' doesn't work in locale service
  return locale
}

export function loadLanguageAsync(locale) {
  return axios
    .get(I18N_URL + '?LanguageCode=' + locale + '&merged=true')
    .then((response) => {
      i18n.setLocaleMessage(locale, response.data)
      loadedLanguages.push(locale)
      return setI18nLanguage(locale)
    })
    .catch((err) => {
      console.log('Locale was not fetched from backend: ' + err)
      return setI18nLanguage(locale, true)
    })
}

/**
 * Parses translations from strings like:
 * [default]Entré Portal Timeregistration[sv-SE]Entré Portal Tidregistrering
 *
 * @param {string} s string to parse
 *
 * @returns {string} translation
 */
export const clientTranslator = (s) => {
  const langMap = {
    sv: '[sv-SE]',
    fi: '[fi-FI]',
    no: '[no-NO]',
    dk: '[da-DK]',
  }

  let locale = langMap[i18n.locale] ? langMap[i18n.locale] : '[en-EN]'
  let translation = ''
  if (s.length && s.includes(locale)) {
    // return proper translation
    translation = s.slice(s.indexOf(locale) + locale.length)
    translation = translation.slice(0, translation.includes('[') ? translation.indexOf('[') : translation.length)
  } else if (s.includes('[default]')) {
    //default
    translation = s.slice(s.indexOf('[default]') + '[default]'.length)
    translation = translation.slice(0, translation.includes('[') ? translation.indexOf('[') : translation.length)
  } else {
    translation = s
  }

  return translation
}
