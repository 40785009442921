<template>
  <v-autocomplete
    ref="endpoint"
    dense
    prepend-inner-icon="far fa-search"
    :value="selectedEndpoint"
    :label="$t('EndpointName') + (required ? ' *' : '')"
    :items="ec.items"
    item-text="endpointName"
    item-value="endpointId"
    clearable
    class="hd-autocomplete"
    :rules="required ? [v => !!v || ' '] : []"
  >
    <template v-slot:selection="{ item }">
      <!-- <HdSystemIcon
        :system-id="item.systemId"
      /> -->
      <div class="ml-2">
        {{ item.endpointName }}
      </div>
    </template>
    <template v-slot:item="{ item }">
      <!-- <HdSystemIcon
        :system-id="item.systemId"
      /> -->
      <div class="ml-2">
        {{ item.endpointName }}
      </div>
    </template>
  </v-autocomplete>  
</template>
<script>
import { EndpointCollection } from '../../../model/endpoint/EndpointCollection'
// import HdSystemIcon from '../../widget/HdSystemIcon'
export default {
  name: 'HdEndpointSelector',

  // components: {
  //   HdSystemIcon
  // },
  props: {
    companyId: {
      required: true,
      type: Number
    },
    selectedEndpoint: {
      required: false,
      type: Number,
      default() { 
        return null
      }
    },
    required: {
      required: false,
      type: Boolean,
      default() {
        return false
      }
    }
  },

  data() {
    return {
      ec: new EndpointCollection({companyId: this.companyId}),
      selection: null
    }
  },
  mounted() {    
    this.ec.pageSize = 400
    this.ec.fetch()
  },
  methods: {
    getValue() {
      let endpoint = this.$refs.endpoint.internalValue      
      return endpoint > 0 ? endpoint : null
    },
  }
}

</script>
<style scoped>

</style>