import adminApiClient from '../../api/admin-api-client'
import authApiClient from '../../api/auth-api-client'
import imageApiClient from '../../api/image-api-client'
import emailApiClient from '../../api/email-api-client'
// import router from '@/router'
import { COMMON_AUTH_PAGE_URL } from '../../util/URL'
import internalClient from '../../api/internal-client'
import dmsClient from '../../api/dms-api-client'
import rssApiClient from '../../api/rss-api-client'
import configApiClient from '../../api/config-api-client'

const authModule = {
  namespaced: true,

  state: {
    token: null,
    isLoggedIn: false,
  },

  mutations: {
    setToken(state, payload) {
      state.token = payload.token
    },
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn
    },
  },

  actions: {
    setToken(context, payload) {
      // We get a token back from "common auth service" in url
      // that is passed to this function in a payload object

      // TODO: Validate token correctly
      const isValid = payload.token && payload.token.length > 0
      if (isValid) {
        localStorage.setItem('user-auth-token', payload.token)

        context.commit('setToken', { ...payload })
        // set token as a default authorization header
        // in the api connector TODO: Combine api clients...
        authApiClient.setToken(payload.token)
        adminApiClient.setToken(payload.token)
        imageApiClient.setToken(payload.token)
        emailApiClient.setToken(payload.token)
        internalClient.setToken(payload.token)
        dmsClient.setToken(payload.token)
        rssApiClient.setToken(payload.token)
        configApiClient.setToken(payload.token)
      }
    },
    // Run on router/index.js
    autoLogin(context) {
      const token = localStorage.getItem('user-auth-token')
      if (token) {
        context.dispatch('setToken', { token })
        return context.dispatch('init', null, { root: true })
      }
    },
    logout(context) {
      context.commit('setToken', { token: null })
      context.commit('setIsLoggedIn', false)
      localStorage.removeItem('user-auth-token')
      window.location.href = COMMON_AUTH_PAGE_URL + btoa(window.location.origin) + '&noSubdivision=true&noCompany=true'
    },
  },

  getters: {
    isAuthenticated(state) {
      return !!state.token && state.isLoggedIn
    },
  },
}

export default authModule
